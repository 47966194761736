import React from 'react';

import { T } from '@utils/languageProvider';
import NavigationCardList from '@components/NavigationCard/list';
import LayoutContentWrapper from '@components/utility/layoutWrapper';

const TradeInLanding = () => {
  const getNavigationList = () => {
    return [
      {
        link: '/trade-in/manage-products/products',
        title: T('products'),
        text: T('tradein.products.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
      {
        link: '/trade-in/manage-products/product-groups',
        title: T('product.groups'),
        text: T('products.groups.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
    ];
  };
  return (
    <LayoutContentWrapper className="module-landing">
      <NavigationCardList navigationList={getNavigationList()} />
    </LayoutContentWrapper>
  );
};

export default TradeInLanding;
