import useStateWithDep from '@common/hooks/useStateWithDep';
import { ChangeableProductCategoryOptionValue } from '@constants/commontypes';
import { isUndefined, omit } from 'lodash';

import {
  emptyProductGroup,
  onNavigate,
  QuestionSetFormInputKey,
} from '../common';

export default function useFormHandlers({ questionSet, id, saveQuestionSet }) {
  const [isProductGroupSelected, setIsProductGroupSelected] = useStateWithDep(
    Boolean(questionSet?.group?.id),
  );

  const handleSubmit = (formValues) => {
    const { group, category } = formValues;
    const modifiedFormValues = omit(
      Object.assign({}, formValues, {
        [QuestionSetFormInputKey.PRODUCT_GROUP]:
          group === String(emptyProductGroup.id) ? null : group,
        [QuestionSetFormInputKey.PRODUCT_CATEGORY]: isProductGroupSelected
          ? ChangeableProductCategoryOptionValue.PHONE
          : category,
      }),
      [isNaN(group) ? QuestionSetFormInputKey.PRODUCT_GROUP : undefined],
    );

    saveQuestionSet(id, modifiedFormValues).then((value) => {
      !id && onNavigate(`/trade-in/question-set/form/${value.id}`);
    });
  };

  const handleChange = (formValues) => {
    const { group: productGroup } = formValues;
    if (productGroup === String(emptyProductGroup.id)) {
      setIsProductGroupSelected(false);
    } else if (!isUndefined(productGroup)) {
      setIsProductGroupSelected(true);
    }
  };

  return {
    handleSubmit,
    handleChange,
    isProductGroupSelected,
  };
}
