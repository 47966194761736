import React, { useEffect } from 'react';

import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';

import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { setActionElements } from '@common/index';
import Button from '@components/Button';
import { brandUrl } from '@constants/serviceUrls';
import { statusActivePassiveFormatter } from '@utils/';

function ProductGroupsList() {
  useEffect(() => {
    setActionElements([
      <Button
        key="excelAdd"
        type="secondary"
        icon="icon-arti"
        onClick={() => onNavigate('/trade-in/change-products/brands/form')}
      >
        {T('new.add').format(T('brand'))}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'name',
      name: T('name'),
      active: true,
      fieldProps: {
        placeholder: T('name'),
      },
    },
  ].map((filter, index) => ({
    ...filter,
    pk: index + 1,
  }));

  const columns = [
    {
      dataIndex: 'name',
      title: T('name'),
    },
    {
      dataIndex: 'is_active',
      title: T('status'),
      render: statusActivePassiveFormatter,
    },
  ].map((column, index) => ({
    ...column,
    key: index + 1,
  }));

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const onRowClick = (row) => {
    onNavigate(`/trade-in/change-products/brands/form/${row.id}`);
  };

  return (
    <LayoutContentWrapper className="stock-lists">
      <DatatableWithFilter
        filterProps={{ staticFilters: filterInputs }}
        datatableProps={{
          rowKey: 'id',
          exportable: false,
          columns: columns,
          onRowClick: onRowClick,
          subtitle: T('brands'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(''),
          url: brandUrl,
        }}
      />
    </LayoutContentWrapper>
  );
}

export default ProductGroupsList;
