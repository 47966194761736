import React, { useEffect, useState, useRef } from 'react';

import { DatatableBox } from '@components/Datatable';
import LayoutWrapper from '@components/utility/layoutWrapper/index';
import { formLayoutTypes } from '@constants/commontypes';
import {
  getQuestionSet,
  saveQuestionSet,
  getAllProductGroups,
  removeQuestion,
} from '@resources/actions/tradeIn';
import { T } from '@utils/languageProvider';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import './style.scss';
import { onNavigate, QuestionSetDynamicForm, datatableColumns } from './common';
import DatatableCustomHeader from './DatatableCustomHeader';
import getFormInputs from './getFormInputs';
import useFormHandlers from './hooks/useFormHandlers';

const QuestionSetForm = ({
  getQuestionSet,
  questionSet,
  saveQuestionSet,
  getAllProductGroups,
  productGroups,
  removeQuestion,
}) => {
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataTableRef = useRef();
  const { id } = useParams();
  const {
    handleChange,
    handleSubmit,
    isProductGroupSelected,
  } = useFormHandlers({
    questionSet,
    id,
    saveQuestionSet,
  });

  useEffect(() => {
    const fetchComponentData = async (id) => {
      try {
        if (id) {
          await getQuestionSet(id);
        }
        await getAllProductGroups();
      } finally {
        setLoading(false);
      }
    };

    fetchComponentData(id);
  }, [getAllProductGroups, getQuestionSet, id]);

  const removeSelection = async () => {
    setTableLoading(true);
    try {
      await Promise.all(
        selectedRowKeys.map((id) => {
          removeQuestion(id);
        })
      );
      // This buffer was added because, in line 72 when we would refetch the items, sometimes a delete item
      // still exists in the database, which results in showing in-correct information
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      try {
        getQuestionSet(id);
      } finally {
        setTableLoading(false);
      }
    }
  };

  const actionButtons = [
    {
      id: 1,
      label: T('delete.selected.items'),
      content: T('delete.selected.question'),
      selectionRequired: true,
      reloadData: true,
      confirm: true,
      customAction: () => {
        removeSelection();
      },
    },
  ];

  const rowSelection = {
    onRowSelection: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleRowClick = (row) => {
    onNavigate(`/trade-in/question-set/form/question/${id}/${row.id}`);
  };

  const formInputs =
    productGroups &&
    getFormInputs({
      productGroups,
      questionSet,
      isProductGroupSelected,
      id,
    });

  return (
    <LayoutWrapper className="question-set-form" loading={loading}>
      <QuestionSetDynamicForm
        title={T('question.set')}
        subtitle={T('question.sets.desc')}
        inputs={formInputs}
        onSubmit={handleSubmit}
        onChange={handleChange}
        layoutType={formLayoutTypes.boxLayout}
      />
      {id && (
        <DatatableBox
          ref={dataTableRef}
          exportable={false}
          onRowClick={handleRowClick}
          remote={false}
          actionButtons={actionButtons}
          dataSource={questionSet?.question_set_items}
          sourceKey="question_set_items"
          rowSelection={rowSelection}
          rowKey="id"
          columns={datatableColumns}
          loading={tableLoading}
          subtitle={T('questions')}
          alwaysShowPagination={false}
          customHeader={<DatatableCustomHeader />}
        />
      )}
    </LayoutWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    questionSet: state.tradeInReducer.questionSet,
    productGroups: state.tradeInReducer.productGroups,
  };
};

const mapDispatchToProps = {
  getAllProductGroups,
  getQuestionSet,
  saveQuestionSet,
  removeQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionSetForm);
