// export const baseURL = "http://localhost:8000/api/v1/";
export const baseURLSet = {
  default: '/api/v1/',
  oms: 'https://akinon-oms.akinon.net/api/v1/',
};
export const loginURL = 'auth/login/';
export const logoutURL = 'auth/logout/';
export const passwordResetURL = 'auth/password/reset/';
export const passwordConfirmURL = 'auth/password/reset/confirm/';
export const checkAuthorizationURL = 'active_user/';
export const channelURL = 'dashboard/';
export const channelOverallURL = 'dashboard/overall_data/';
export const channelTopCategoriesURL = 'dashboard/top_categories/';
export const channelTopProductsURL = 'dashboard/top_products/';
export const channelTopCitiesURL = 'dashboard/top_cities/';
export const channelChartURL = 'dashboard/chart/';
export const getProductDetailURL = 'tradableitem/excel_import/';
export const getProductRuleDetailURL = 'products/excel_import/';

export const patchProductSpecURL = 'products/{0}/';
export const deleteProductSpecURL = 'products/{0}/';
export const getProductsURL = 'products/detailed/';
export const getProductVariant = 'products/?parent={0}';
export const getFiltersURL = 'attributes/detailed/?limit=99999&sort=name';
export const getAttributeSetURL = 'attribute_set/{0}/detailed/';
export const getAttributesSetURL = 'attribute_set/?limit=100';
export const getAttributeURL = 'attributes/{0}/detailed/';
export const getPermissionsURL = 'fe_permissions/detailed_list/';
export const getProductCombineSourceURL =
  'products/?product_type=0&group_parent_set!={0}';
export const getProductCombineTargetURL =
  'group_product_items/detailed/?from_product={0}';
export const getProductVariantsURL = 'products/detailed/?parent={0}';
export const getProductSameModelURL =
  'products/detailed/?parent__isnull=True&product_type=0&base_code=';
export const combineProductsURL = 'group_product_items/';
export const unCombineProductsURL = 'group_product_items/{0}/';
export const getCombineFilterURL = 'products/?limit=99999&sort=name';
export const getStockListURL = 'stock_list/';
export const getCollectionListURL = 'product_collections/detailed/';
export const getProductCollectionFormURL = 'product_collections/';
export const getProductCollectionURL = 'product_collections/{0}/';
export const getStockURL = 'stock_list/{0}/';
export const getStockDetailURL = 'product_stock/detailed/?stock_list={0}';
export const getPriceDetailURL = 'product_price/detailed/?price_list={0}';
export const getProductImageURL = 'product_image/';
export const getProductVideoURL = 'product_video/';
export const makeSpecialImageURL = 'product_image/{0}/make_special/';
export const productCollectionURL = 'remote/{0}/product_collections/';

export const catalogURL = 'catalogs/';
export const postCatalogItemsURL = 'catalog_items/';
export const getCatalogItemsURL = 'catalog_items/{0}/';
export const getCatalogURL = 'catalogs/{0}/';
export const getCatalogDetailURL = 'catalogs/{0}/detailed';
export const getCatalogsURL = 'catalogs/detailed/?limit=100';
export const getCatalogProductList = 'catalogs/{0}/product_list/';
export const getCatalogCategoryList =
  'catalogs/{0}/category_list/?active_catalog_item__isnull={1}';
export const getCatalogPriceList = 'catalogs/{0}/price_list/';
export const getCatalogStockList = 'catalogs/{0}/stock_list/';
export const postProductCategory = 'product_category/';
export const deleteProductCategory = 'product_category/{0}/';
export const getCategoryNode = 'category_node/{0}/';
export const getCategoryNodeBase = 'category_node/';
export const getProductCategory = 'product_category/detailed/?category={0}';
export const getPriceList = 'price_list/?limit=1000';
export const getStockList = 'stock_list/?limit=1000';
export const priceListURL = 'price_list/';
export const getCatalogFeedDetailURL = '/feed_conf/{0}/';
export const getCatalogFeedListURL = '/feed_conf/';
export const getContentTypesURL = 'content_types/?limit=100';
export const getCampaignURL = 'remote/{0}/basket_offers/?offer_type=sitewide';
export const getVoucherURL =
  'remote/{0}/basket_offers/?user__isnull=False&voucher_code__isnull=True';
export const getDiscountURL =
  'remote/{0}/basket_offers/?&voucher_code__isnull=False;';
export const getCollectionDetailURL = '/product_collections/{0}/product_list/';
export const getTreeDataURL = 'category_tree/{0}/tree/';
export const getCategoryMove = 'category_node/move/';
export const getCategorySchema = 'schema_types/categorynode/';
export const postProductCollectionURL = 'product_collection_items/';
export const deleteProductCollectionURL = 'product_collection_items/{0}/';
export const getCollectionDetailItemListURL =
  'product_collections/{0}/item_list/';
export const getProductCollectionsURL = 'remote/{0}/product_collections/';
export const getErrorReportURL =
  'error_reports/?catalog={0}&is_ok=False&limit=99999&target_content_type={1}';
export const getMappingErrorReportURL = 'error_reports/{0}';
export const patchMappingErrorReportURL =
  'error_reports/{0}/update_error_code/';
export const deleteMappingErrorReportURL = 'error_reports/{0}/';
export const getStockLocationMovementsURL = 'oms/stocklocations/dashboard2/';
export const getStockLocationMovementDetailURL =
  'oms/stocklocations/{0}/dashboard_detail/';
export const getStockLocationsURL = 'oms/stocklocations/';
export const getStockLocationsSortedURL =
  'oms/stocklocations/?limit=99999&sort=name';
export const transferOrderListURL = 'oms/transfer_orders/';
export const getTransferDetailURL = 'oms/transfer_orders/{0}/';
export const categoryTreeURL = 'category_tree/';

export const getDistributionURL = 'oms/rules/scenarios/?sort=priority';
export const setPriorityURL = 'oms/rules/scenarios/set_priority/';
export const getOmsScenarioOptions = 'oms/rules/scenarios/options/';

export const packageOrderListURL = 'oms/packages/';
export const getOmsOrderDetail = 'oms/orders/{0}/';
export const getTransfers = 'oms/transfer_orders/?order__number={0}';
export const getOmsPackageDetail = 'oms/packages/{0}/';
export const getOmsOrders = 'oms/orders/';
export const getProductSpecificationsURL = 'attributes/detailed/';
export const getProductListingTypes = 'attribute_input_types/';
export const getProductSpecificationSetsURL = 'attribute_set/detailed/';
export const getAttributeSetTypesURL = 'attribute_set_types/';
export const getAttributeDetail = 'attributes/{0}/';
export const postAttributeDetail = 'attributes/';
export const postOptionalAttributeDetail = 'attribute_value/';
export const deleteOptionalAttributeDetail = 'attribute_value/{0}/';
export const getOptionalAttributeDetail = 'attribute_value/?attribute={0}'; // @FTODO : getSpecificationsDataTable URL ile aynı ..
export const getSpecificationsDatatable =
  'attribute_value/?attribute={0}&limit=9999';
export const getOmsTotalCountURL = 'oms/stats/total-counts/?limit=9999';
export const getOmsShippingPackagesURL =
  'oms/stats/total-shipped-packages/?limit=5';
export const getOmsShippedPackageURL = 'oms/packages/?state__enum_value=700';
export const getOmsWaitingPackageURL = 'oms/packages/?state__enum_value=100';
export const getOmsTotalPackageURL =
  'oms/stats/total-shipped-packages/?limit=5';
export const getOmsTotalTransferURL = 'oms/stats/total-transfers/?limit=5';
export const getOmsPackagePerHourURL =
  'oms/stats/package-counts-per-hour/?sort=hour';
export const getOmsCustomersURL = 'oms/customers/';
export const getOmsCargoDetail = 'oms/cargo-company/{0}/';
export const getOmsCargoCompany = 'oms/cargo-company/';
export const getOmsShipmentEnums = 'oms/cargo-company/shipment-enums/';
export const getOmsProductReport = 'oms/products/query-product?sku={0}';
export const getOrderStatusList =
  'oms/states/?enum_class=OrderStatus&model_name=order';
export const getPackageStatusList = 'oms/states/?enum_class=PackageStatus';
export const getStockLocationList = 'oms/stocklocations/?sort=name&limit=99999';
export const getStockLocationDetail = 'oms/stocklocations/{0}/';
export const getStockLocationByID = 'oms/stocklocations/dashboard/?id={0}';
export const stockLocationEnginesURL = 'oms/stocklocation-engines/';
export const fillStockEngineURL =
  'oms/stocklocation-engines/fill_stock_engine/';
export const getStockLocationPackages =
  'oms/packages/?stock_location={0}&state={1}';
export const getCountriesURL = 'oms/countries/';
export const getCitiesURL = 'oms/cities/?limit=99999';
export const getTownshipsURL = 'oms/townships/?limit=99999';
export const getDistrictsURL = 'oms/districts/?limit=99999';
export const setStockLocationPriorityURL = 'oms/stocklocations/set_priority/';
export const packageDetailActionButtonURL = 'oms/packages/{0}/command/';
export const transferDetailActionButtonURL = 'oms/transfer_orders/{0}/command/';
export const omsChannelsURL = 'oms/channels/';

export const packagingRuleURL = 'oms/rules/packaging_rules/';
export const scenariosURL = 'oms/rules/scenarios/';

export const getProductAttributeSetsDetailURL = 'attribute_set/{0}/detailed/';
export const getTransferLocationMovementsURL =
  'oms/stocklocations/dashboard_transfer2/';
export const getTransferLocationMovementDetailURL =
  'oms/stocklocations/{0}/dashboard_transfer_detail/';
export const getTransferStatusList =
  'oms/states/?enum_class=TransferStatus&model_name=transferorder';
export const getTransferLocationDetailURL =
  'oms/transfer_orders/?source={0}&state={1}';
export const allProductPropertiesURL =
  'attributes/detailed/?limit=99999&sort=name';
export const productPropertiesByAttributeSetURL =
  'attribute_config/detailed/?attribute_set={0}&limit=99999&sort=order';
export const getPackageDetailByPackageNumberURL = 'oms/packages/?number={0}';
export const getTransferDetailByTransferNumberURL =
  'oms/transfer_orders/?number={0}';
export const getOrderDetailByOrderNumberURL = 'oms/orders/?number={0}';
export const orderListURL = 'orders/detailed/';
export const allChannelsURL = 'channels/?limit=500';
export const customersURL = 'customers/detailed/';
export const customerDetailURL = 'customers/{0}/detailed/';
export const usersByChannelURL = 'remote/{0}/users/';
export const getUsersByChannel = 'remote/{0}/user_search/';
export const widgetListByChannelURL = 'remote/{0}/widgets/detailed/';
export const widgetTypeListByChannelURL = 'remote/{0}/widget_types/';
export const widgetDetailByChannelURL = 'remote/{0}/widgets/';
export const widgetTypesByChannelURL = 'remote/{0}/widget_types/?limit=99999';
export const flatPagesByChannelURL = 'remote/{0}/flat_pages/';
export const basketOffersByChannelUserURL = 'remote/{0}/basket_offers/';
export const getBulkVouchersURL = 'remote/{0}/bulk_voucher_codes/';
export const addressesByCustomerURL = 'addresses/?customer={0}';
export const addressesByUserURL = 'remote/1/users/{0}/address-list/'; //TODO: channel redux
export const userSchemaTypeByChannelURL = 'remote/{0}/schema_types/user/';
export const resetPasswordURL = 'remote/{0}/users/password/reset/';
export const getPaymentOptions = 'payment_options/?channel={0}&limit=20&page=1';
export const getBankDetailURL = 'banks/';
export const getBanksURL = 'remote/{0}/banks/';
export const getBankAccounts = 'bank_accounts/';
export const getPaymentOptionDetailURL = 'payment_options/{0}/';
export const postPaymentOptionDetailURL = 'payment_options/';
export const getRemoteLanguageURL = 'remote/{0}/languages/';
export const getLanguageURL = 'languages/';
export const getShippingOptions = 'remote/{0}/shipping_options/';
export const getShippingCompanies = 'cargos/';
export const getCancellationReason = 'cancellation_reasons/';
export const getRetailStockGatewayURL = 'remote/{0}/retail_stock_gateways/';
export const getCardTypes = 'card_types/';
export const getCardTypesURL = 'remote/{0}/card_types/';
export const getFacetConfURL = 'remote/{0}/facet/';
export const getLoyaltyAccountURL = 'remote/{0}/loyalty_account/';
export const getLoyaltyTransferURL = '/remote/{0}/loyalty_transfer/';

export const getLandingPagesURL = 'remote/{0}/landing_pages/detailed/';

export const getSpecialPagesURL = 'remote/{0}/special_pages/detailed/';
export const getSpecialPageDetailURL = 'remote/{0}/special_pages/{1}/';
export const postSpecialPageDetailURL = 'remote/{0}/special_pages/';

export const getCollectionList =
  'remote/{0}/product_collections/?is_active=True&limit=1000&sort=-created_date';
export const collectionListURL = 'remote/{0}/product_collections/';

export const getCollectionWidgetsURL =
  'remote/{0}/product_collection_widgets/detailed/';
export const CollectionWidgetURL = 'remote/{0}/product_collection_widgets/';

export const formListURL = 'remote/{0}/contact-us-subjects/';

export const getWidgetTypeURL = 'remote/{0}/widget_types/?limit=99999';

export const SEOSettingsURL = 'remote/{0}/seo/';
export const URLSettingsURL = 'remote/{0}/pretty_urls/';
export const URLRedirectsURL = 'remote/{0}/redirects/';

export const downloadURL = '/download/';

export const attributeSetTypeURL = 'attribute_set_type/';
export const attributeSetURL = 'attribute_set/';
export const attributeConfigURL = 'attribute_config/';
export const orderDetailedURL = 'orders/{0}/detailed/';
export const orderCancelURL = 'orders/{0}/cancel/';
export const makeRefundCompleteURL = 'orders/{0}/update_cancel_status/';
export const orderChangeShippingAddressURL =
  'orders/{0}/change_shipping_address/';
export const orderChangeBillingAddressURL =
  'orders/{0}/change_billing_address/';
export const cancellationReasonsURL =
  'cancellation_reasons/?is_active=true&limit=100';
export const orderAgreementsURL = 'remote/{0}/order_agreements/';
export const fundsTransferTransactionURL = 'funds_transfer_transactions/';
export const payOnDeliveryTransactionsURL = 'pay_on_delivery_transactions/';

export const cancellationRequests = 'cancellation_requests/';

export const cancellationPlansURL = 'cancellation_plans/';
export const posListURL = 'pos/';
export const posSchemaURL = 'pos/schema/';

export const preProductsDetailedURL = 'pre_products/detailed/';
export const preProductsURL = 'pre_products/';
export const getTransaction = 'remote/{0}/transactions/';

export const promotionGatewaysURL = 'remote/{0}/promotion_gateways/';

export const serviceLogURL = 'integration/log/';
export const serviceLogDownloadURL =
  baseURLSet.default + 'integration/s3log/{0}/download/';

export const deliveryOptionsURL = 'remote/{0}/delivery_options/';

export const socialAppsURL = 'remote/{0}/social-app/';
export const sitesURL = 'remote/{0}/sites/';

export const catalogGroupsURL = 'catalog_groups/';
export const channelGroupsURL = 'channel_groups/';

export const cardsURL = 'cards/';
export const groupsURL = 'groups/';
export const binNumberURL = 'bin_numbers/';
export const installmentsURL = 'installments/';

export const usersURL = 'users/';

export const permissionGroupURL = 'fe_permission_groups/';

export const getNavigationURL = 'remote/{0}/menu_items/';
export const getNavigationSchemaURL = 'remote/{0}/schema_types/menuitemmodel/';
export const moveNavigationNodeURL = 'remote/{0}/menu_items/move/';
export const schemaTypesURL = 'schema_types/';
export const contentTypesURL = 'content_types/';
export const channelsURL = 'channels/';
export const channelTaskURL = 'channel_task/';

export const getLandingAttributesURL = 'remote/{0}/attributes';
export const getLandingAttributeValueURL = 'remote/{0}/attribute_value/';
export const getLandingPageTypes = 'remote/{0}/landing_page_types';
export const categoryNodeLandingPages =
  'remote/{0}/category_node_landing_pages/detailed/';
export const categoryNode = 'remote/{0}/category_node/';
export const landingPageTypes = 'remote/{0}/landing_page_types/';
// TODO: add url postfix to identifiers
export const sortOptionUrl = 'sort_options/';
export const sortingAlgorithms = 'sorting_algorithms/';
export const addSortingAlgorithms = 'sort_options/{0}/add_sorting_algorithms/';
export const getSortingAlgorithms = 'sorting_algorithms/';
export const getAttributesURL = 'attributes/';
export const attributeValueSearchURL = 'attribute_value_search/';

export const retailStoresURL = 'retail_stores/';
export const retailStoreDetailURL = 'retail_stores/{0}/detailed/';
export const retailStoresCitiesUrl = 'cities/';
export const retailStoresCountriesUrl = 'countries/';
export const retailStoresTownshipsUrl = 'townships/';
export const retailStoresDistrictsUrl = 'districts/';
export const patchRetailStoresURL = 'retail_stores/{0}/';
export const postRetailStoreDistrictUrl = 'retail_stores/{0}/add_districts/';
export const retailStoreTypeURL = 'retailstore_type/';
export const sortOptionDetailsURL = 'sort_options/{0}/detailed/';
export const getProductsBySortOptionURL =
  'sorting_algorithms/{0}/product_list/';
export const getProductItemListURL = 'sorting_algorithms/{0}/item_list/';
export const productSortingURL = 'product_sorting_items/';
export const productCheckListURL = 'catalogs/{0}/check_list/';

export const remoteSchedules = 'remote/{0}/schedules/';
export const remoteScheduleRules = 'remote/{0}/schedule_rules/';

export const UrlGeneratorConfigURL = 'remote/{0}/url_generator_configs/';

export const landingPagesURL = 'remote/{0}/landing_pages/';

export const getTransferDistributionURL =
  'oms/rules/transfer_scenarios/?sort=priority';
export const setTransferPriorityURL =
  'oms/rules/transfer_scenarios/set_priority/';
export const getOmsTransferScenarioOptions =
  'oms/rules/transfer_scenarios/options/';

export const transferScenarioRuleURL = 'oms/rules/transfer_rules/';
export const transferScenarioURL = 'oms/rules/transfer_scenarios/';

export const getCargoCompanyURL = 'oms/cargo-company';

export const activeUserURL = 'active_user/';
export const saveCategoryNodeLandingPagesURL =
  'remote/{0}/category_node_landing_pages/';
export const transactionsURL = 'transactions/';
export const orderShippingInfosURL = 'order_shipping_infos/';

export const auditContentTypeURL = 'audit/events/content_type/';
export const contentTypeFieldURL = 'content_types/{0}/fields/';

export const customerURL = 'customers/{0}/';

export const getMappingsURL = 'mappings/';
export const mappingDetailURL = 'mappings/{0}/';
export const getPreAttributesURL = 'pre_attributes/';

export const orderUrl = 'orders/{0}';
export const cancellationPlansURLWithId = 'cancellation_plans/{0}';
export const getDataSourcesURL = 'data_sources/';
export const getDataSourceDetailURL = 'data_sources/{0}/';
export const getReconciliationsURL = 'reconciliations/';
export const getReconciliationDetailURL = 'reconciliations/{0}/';
export const getReconciliationItemsURL = 'reconciliations_items/';
export const getReconciliationItemDetailURL =
  'reconciliations/{0}/reconciliation_items/';

export const googleMerchantFeedAdapterURL =
  'remote/{0}/google_merchant_feed_adapter/';
export const googleMerchantURL = 'remote/{0}/google_merchant_attribute_mapper/';

export const cachedAttributeURL = 'cached_attributes/detailed/';

export const anonymizeUserURL = 'remote/{0}/anonymize-user/';

export const integrationUrl = 'integration/';

export const hooksURL = 'oms/hooks/';
export const webHooksByTransferOrderURL =
  'oms/transfer_orders/{0}/webhook_events/';
export const webHooksByPackageOrderURL = 'oms/packages/{0}/webhook_events/';
export const resendWebHookEventURL = 'oms/oms_hook_events/resend-event/';

export const bulkBinNumberURL = 'cards/{0}/bulk_create/';

export const categoryNodeUrl = 'remote/{0}/schema_types/categorynode/';

export const bulkBankList = 'banks/bulk_create/';

export const orderItemListURL = 'order_items/detailed/';

export const priceOptimizationURL =
  'https://samsung-dynamicprice.akinon.net/api/v1/';

export const priceOptimizationPriceList = 'pricelist/';
export const priceOptimizationProductPrice = 'productprice/';
export const priceOptimizationScheduledProductPrice = 'scheduledproductprice/';
export const addCatalogItemsBulkURL = 'catalog_items/bulk_create/';
export const deleteCatalogItemsBulkURL = 'catalog_items/bulk_delete/';

export const stockListsURL = 'stock-lists/';
export const stockListRulesURL = 'stock-list-rules/';
export const providersURL = 'providers/';
export const stockLocationsUrl = 'stock-locations/';
export const stocksUrl = 'stocks/';
export const getProductCategoryBulkCreateStatus =
  'product_category/{0}/bulk_create_status/';
export const orderTrackingNumberUpdateStatusURL =
  'orders/bulk_tracking_number_update_status/';
export const orderTrackingNumberUpdateURL =
  'orders/bulk_tracking_number_update/';
export const postProductCategoryBulkCreate = 'product_category/bulk_create/';
export const productSortingBulkUpdateStatusURL =
  'product_sorting_items/{0}/bulk_update_status/';
export const productSortingBulkUpdateURL = 'product_sorting_items/bulk_update/';

export const questionUrl = 'question/';
export const tradableItemUrl = 'tradableitem/';
export const tradaRuleSetUrl = 'trade_ruleset/';
export const brandUrl = 'brand/';
export const seriesUrl = 'series/';
export const questionSetUrl = 'questionset/';
export const productsUrl = 'products/';
export const productGroupsUrl = 'product_groups/';
export const bulkProductsUrl = 'products/bulk_create/';
export const removeTradebleItemURL = '/remove_tradable_items/';
export const tradableItemAmountURL = '/update_tradable_items/';
export const productProductGroupsUrl = 'product_groups/{0}/add_products/';
export const simplifiedProductsUrl = 'simple_product_list/';
export const productGroupsAdditionalDiscount =
  'product_groups/{0}/additional_discount/';

export const changeProductBulkUpdate = '/tradableitem/excel_import/';
