import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { forceRerender } from '@utils/index';
import Landing from './Landing';

import ProductsList from './Products/ProductsList';
import ProductsDetail from './Products/ProductsDetail';
import ProductGroupsDetail from './ProductGroups/ProductGroupsDetail';
import ProductGroupsList from './ProductGroups/ProductGroupsList';

const ChangeProducts = () => {
  return (
    <Switch>
      <Route
        path="/trade-in/manage-products/products/form/:id?"
        component={ProductsDetail}
      />

      <Route
        path="/trade-in/manage-products/products"
        component={forceRerender(ProductsList)}
      />

      <Route
        path="/trade-in/manage-products/product-groups/form/:id?"
        component={ProductGroupsDetail}
      />
      <Route
        path="/trade-in/manage-products/product-groups"
        component={ProductGroupsList}
      />

      <Route path="/trade-in/manage-products" component={Landing} />
    </Switch>
  );
};
export default ChangeProducts;
