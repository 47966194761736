import React, { useState, useEffect } from 'react';

import { navigator } from '@common/navigation';
import { DynamicFormWithBox } from '@components/DynamicForm';
import { inputTypes } from '@components/DynamicForm/constants';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import {
  changeableProductCategoryOptions,
  formLayoutTypes,
  validationTypes,
} from '@constants/commontypes';
import { brandUrl, seriesUrl } from '@constants/serviceUrls';
import { getTradableItem, saveTradableItem } from '@resources/actions/tradeIn';
import { T } from '@utils/languageProvider';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const DynamicForm = DynamicFormWithBox('stock-locations-detail');

const ChangeableProductsDetail = (props) => {
  const { getTradableItem, tradableItem, saveTradableItem } = props;
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    id
      ? getTradableItem(id).then(() => {
          setLoading(false);
        })
      : setLoading(false);
  }, [getTradableItem, id]);

  const generateFormInputs = () => {
    const defaultValue = id && tradableItem;
    return [
      {
        name: T('brand'),
        key: 'brand',
        data_type: { value: inputTypes.remoteselect.alias },
        url: brandUrl,
        valueKeyName: 'id',
        labelKeyName: 'name',
        objectKey: 'results',
        default_value: defaultValue?.series?.brand?.id?.toString(),
        fieldProps: {
          placeholder: T('enter.variable').format(T('brand')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('brand'))],
        },
      },
      {
        name: T('series'),
        key: 'series',
        data_type: { value: inputTypes.remoteselect.alias },
        url: seriesUrl,
        valueKeyName: 'id',
        labelKeyName: 'name',
        objectKey: 'results',
        relatedTo: 'brand',
        relatedKey: 'brand',
        default_value: defaultValue?.series?.id.toString(),
        fieldProps: {
          placeholder: T('enter.variable').format(T('series')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('series'))],
        },
      },
      {
        name: T('model'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        default_value: defaultValue?.name,
        fieldOptions: {
          rules: [validationTypes.required(T('model'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('model')),
        },
      },
      {
        name: T('highestValue'),
        key: 'price',
        data_type: { value: inputTypes.input.alias },
        default_value: defaultValue?.price,
        fieldOptions: {
          rules: [validationTypes.required(T('highestValue'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('highestValue')),
        },
      },
      {
        name: T('status'),
        key: 'is_active',
        data_type: { value: inputTypes.select.alias },
        default_value: defaultValue?.is_active,
        fieldOptions: {
          rules: [validationTypes.required(T('status'))],
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('status')),
          options: [
            { label: T('active'), value: true },
            { label: T('passive'), value: false },
          ],
        },
      },
      {
        name: T('category'),
        key: 'category',
        data_type: { value: inputTypes.select.alias },
        visible_values: changeableProductCategoryOptions,
        fieldOptions: {
          rules: [validationTypes.required(T('category'))],
        },
        default_value: tradableItem.category,
        fieldProps: {
          placeholder: T('enter.variable').format(T('category')),
        },
      },
    ].map((input, index) => ({
      ...input,
      pk: index + 1,
    }));
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const onSubmit = (values) => {
    const { brand, ...restValues } = values;
    const { series } = tradableItem;
    let seriesId = undefined;
    if (!values.series) {
      seriesId = series.id.toString();
    }
    saveTradableItem(id, {
      series: seriesId,
      ...restValues,
    }).then(() => onNavigate('/trade-in/change-products/changeable-products'));
  };

  const inputFields = generateFormInputs();

  return (
    <LayoutContentWrapper
      className="changeable-products-form"
      loading={loading}
    >
      <DynamicForm
        title={T('add.edit').format(T('changeable.products'))}
        subtitle={T('detail.desc').format(T('changeable.products'))}
        onSubmit={onSubmit}
        // onChange={onChange}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    tradableItem: state.tradeInReducer.tradableItem,
  };
};
const mapDispatchToProps = {
  getTradableItem,
  saveTradableItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChangeableProductsDetail);
