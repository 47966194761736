import React, { Component } from 'react';
import FileSaver from 'file-saver';
import axios from 'axios';

import { store } from '@resources/store';
import { getAllUrlParams } from '@utils/index.js';
import { T } from '@utils/languageProvider';
import { readStorageItem, removeStorageItem } from '@common/storage';
import { tokenHeaderKEY } from '@constants/';
import { getAPILanguage } from '@common/';

import LayoutWrapper from '@components/utility/layoutWrapper/index';
import Block from '@components/Block/index.js';
import Anchor from '@components/Anchor';

import './style.scss';
import { downloadUrlAndParams } from './constants';

class Download extends Component {
  state = {
    downloadUrl: '',
  };

  componentDidMount() {
    const token = readStorageItem('tkn');;
    const pathUrl = readStorageItem('downloadURL');
    const fileDownload = readStorageItem('fileDownload');
    const fileDownloadError = readStorageItem('fileDownloadError');

    fileDownload && removeStorageItem('fileDownload');
    fileDownloadError && removeStorageItem('fileDownloadError');

    if (fileDownload) {
      this.saveFileFromResponse(JSON.parse(fileDownload));
    } else if (fileDownloadError) {
      this.showFileFormResponse(JSON.parse(fileDownloadError));
    } else {
      let params = getAllUrlParams(pathUrl);
      const downloadParams = downloadUrlAndParams(params);
      axios
        .get(downloadParams.url, {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Accept-Language': getAPILanguage(),
            'content-type': 'text/html; charset=utf-8',
            Authorization: `${tokenHeaderKEY} ${token}`,
          },
        })
        .then((response) => {
          this.saveFileFromResponse(response);
        })
        .catch((error) => {
          this.saveFileFromResponse(error.response);
        });
    }
  }

  saveFileFromResponse = (response) => {
    const respHeaders = response.headers;
    if (response.data && respHeaders['content-type'] && respHeaders['content-disposition']) {
      const blob = new Blob([response.data], { type: respHeaders['content-type'] });
      const filename = respHeaders['content-disposition'].split('"').slice(1, -1)[0];
      FileSaver.saveAs(blob, filename);
      this.setState({ downloadUrl: filename });
    }
  };

  showFileFormResponse = (response) => {
    let decoder = new TextDecoder('utf-8');
    this.setState({
      downloadUrl:
        typeof response?.messages?.[0] === 'string'
          ? response.messages[0]
          : decoder.decode(response.data),
    });
  };

  openXLSFile = () => {
    const { downloadUrl } = this.state;
    window.open(downloadUrl, '_blank');
  };

  render() {
    const { downloadUrl } = this.state;
    
    return (
      <LayoutWrapper>
        <Block className="download-info-message">{T('download.page.info')}</Block>
        <Block>
          <Anchor onClick={this.openXLSFile}>{downloadUrl}</Anchor>
        </Block>
      </LayoutWrapper>
    );
  }
}

export default Download;
