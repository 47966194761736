import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { forceRerender } from '@utils/index';
import Landing from './Landing';

import ChangeableProductsList from './ChangeableProducts/ChangeableProductsList';
import ChangeableProductsDetail from './ChangeableProducts/ChangeableProductsDetail';

import ChangeableProductRulesList from './ChangeableProductRules/ChangeableProductRulesList';
import ChangeableProductRulesDetail from './ChangeableProductRules/ChangeableProductRulesDetail';

import BrandsDetail from './Brands/BrandsDetail';
import BrandsList from './Brands/BrandsList';

import SeriesDetail from './Series/SeriesDetail';
import SeriesList from './Series/SeriesList';

const ChangeProducts = () => {
  return (
    <Switch>
      <Route
        path="/trade-in/change-products/changeable-product-rules/form/:id?"
        component={forceRerender(ChangeableProductRulesDetail)}
      />
      <Route
        path="/trade-in/change-products/changeable-product-rules"
        component={ChangeableProductRulesList}
      />
      <Route
        path="/trade-in/change-products/changeable-products/form/:id?"
        component={ChangeableProductsDetail}
      />
      <Route
        path="/trade-in/change-products/changeable-products"
        component={forceRerender(ChangeableProductsList)}
      />

      <Route
        path="/trade-in/change-products/brands/form/:id?"
        component={BrandsDetail}
      />

      <Route
        path="/trade-in/change-products/brands"
        component={forceRerender(BrandsList)}
      />

      <Route
        path="/trade-in/change-products/series/form/:id?"
        component={SeriesDetail}
      />

      <Route
        path="/trade-in/change-products/series"
        component={forceRerender(SeriesList)}
      />

      <Route path="/trade-in/change-products" component={Landing} />
    </Switch>
  );
};
export default ChangeProducts;
