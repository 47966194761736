import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { forceRerender } from '@utils/';

import Landing from './Landing';
import ChangeProducts from './ChangeProducts';
import QuestionSetList from './QuestionSet/QuestionSetList';
import QuestionSetDetail from './QuestionSet/QuestionSetDetail';
import Question from './QuestionSet/Question';
import ManageProducts from './ManageProducts';

const TradeIn = () => {
  return (
    <Switch>
      <Route path="/trade-in/change-products" component={ChangeProducts} />
      <Route
        path="/trade-in/question-set/form/question/:questionSetID?/:id?"
        component={Question}
      />
      <Route
        path="/trade-in/question-set/form/:id?"
        component={forceRerender(QuestionSetDetail)}
      />
      <Route path="/trade-in/question-set" component={QuestionSetList} />

      <Route path="/trade-in/manage-products" component={ManageProducts} />

      <Route path="/trade-in" component={Landing} />
    </Switch>
  );
};
export default TradeIn;
