import debounce from "lodash/debounce";
import { Cookies } from "react-cookie";

import moment from "moment";
import 'moment/min/locales';

import { getCurrentLanguage } from "@components/LanguageSwitcher/config";
import { store } from "@resources/store";
import { changeReadReceipt } from "@resources/actions/app";
import { readStorageItem, writeStorageItem } from "./storage";
import { apiLanguageKey, permissionSlugSuffix, timeFormatWithDateAndTime, readResponseMessageKey } from "@constants/";
import { orderStatuses, clientTypes, orderTransactionTypes, customerTypes } from "@constants/commontypes";
import { T } from "@utils/languageProvider";

import message from "@components/Message";

let actionElementSetup = function () { };
let specialBreadcrumbSetup = function () { };
let pageClassName = "";
const cookies = new Cookies();

export function setupActionElements(action) {
  actionElementSetup = action;
}

export function setupSpecialBreadCrumb(action, pageClass) {
  specialBreadcrumbSetup = action;
  pageClassName = pageClass;
}

export function setActionElements(elements) {
  actionElementSetup(elements);
}

export function setSpecialBreadcrumb(breadcrumb, pageClass) {
  specialBreadcrumbSetup(breadcrumb, pageClass);
}

export function checkAuth(actionName) {
  const { authorizationData } = store.getState().authReducer;
  const checkPermission = permission =>
    !!authorizationData.find(
      ({ slug, is_visible_for_user }) =>{
        return is_visible_for_user && (slug === permission || slug === `${permission}${permissionSlugSuffix}`)
      }
    );

  switch (typeof actionName) {
    case "string":
      return checkPermission(actionName);
    case "function":
      return actionName(checkPermission, authorizationData);
    case "object":
      if (Array.isArray(actionName)) return actionName.some(checkPermission);
    default:
      throw new Error(
        `Permission checker String | Array | Function türlerinden biri olmalıdır. ${typeof actionName} kullanılmış.`
      );
  }
}

export function calculateDifference(obj1, obj2) {
  return Object.keys(obj1)
    .filter(key => obj1[key] !== obj2[key])
    .map(key => ({
      name: key,
      value: obj2[key]
    }));
}

const openNotification = debounce((props) => {
  const messageContent = props.message || props.content;

  message({content:messageContent, ...props});
}, 500);

export function logResponseMessages(props, isNotify = true) {
  const { type , message, status } = props;
  const storedMessages = JSON.parse(readStorageItem('responseMessages')) || [];
  try {
  const date = moment(new Date()).locale(`'${getCurrentLanguage().locale}'`).format(timeFormatWithDateAndTime);
  storedMessages.push({ type, message: message.substring(0, 200),status, date})
  writeStorageItem('responseMessages', JSON.stringify(storedMessages));
  writeStorageItem(readResponseMessageKey, JSON.stringify(false));
  store.dispatch(changeReadReceipt(false))
  isNotify && openNotification(props);
  }catch(error){
    console.log(error);
  }
}

export function getContextChannelInfo() {
  const channelPk = readStorageItem('contextChannel');
  const { channels } = store.getState().appReducer;
  return channels.find(item => item.pk == channelPk);
}

export function getContextChannelPk(){
  const channelPk = readStorageItem('contextChannel');
return channelPk;
}

export function getOrderStatusDisplay(value) {
  let item = orderStatuses.find(item => value == item.value);
  return item && item.label;
}

export function getClientType(value) {
  let item = clientTypes.find(item => value == item.value);
  return item && item.label;
}

export function getTotalAmount(order) {
  return order.amount * 1 + (order.discount_amount * 1 || 0);
};
export function getTotalAmountFixed(order, fixNumber = 2) {
  return getTotalAmount(order).toFixed(fixNumber);
}

export function getDiscountAmount(discountItemSet) {
  return discountItemSet && discountItemSet.length > 0 && discountItemSet.map(item => item.amount)
    .reduce((accumulator, current) => parseFloat(accumulator) + parseFloat(current));
}

export function getGender(customer) {
  if (!customer) {
    return;
  }

  if (customer.gender === 'male') {
    return T("male");
  } else if (customer.gender === 'female') {
    return T("female");
  } else {
    return customer.gender;
  }
};

export function getOrderTransactionType(value) {
  let item = orderTransactionTypes.find(item => value == item.value);
  return item ? item.label : value;
}

export function getCustomerType(value) {
  let item = customerTypes.find(item => value == item.value);
  return item ? item.label : value;
}

export function getAPILanguage() {
  return cookies.get(apiLanguageKey)
}

export function setAPILanguage(selectedLanguage) {
  cookies.set(apiLanguageKey, selectedLanguage, { path: '/'});
}
