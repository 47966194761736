import React from 'react';
import IsoWidgetsWrapper from '../Widget/widgets-wrapper';
import StickerWidget from '../Sticker';
const card = ({ icon, color, title, text, image, onClick, intrinsic }) => {
  return (
    <IsoWidgetsWrapper onClick={onClick}>
      <StickerWidget
        title={title}
        text={text}
        icon={icon}
        intrinsic={intrinsic}
        image={image}
        bgColor={color}
      />
    </IsoWidgetsWrapper>
  );
};

export default card;
