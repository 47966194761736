import React from 'react';
import { navigator } from '@common/navigation';
import { DynamicFormWithBox } from '@components/DynamicForm';
import { T } from '@utils/languageProvider';

export const emptyProductGroup = {
  id: 0,
  name: T('empty.product.group'),
};

export const questionSetDynamicFormId = 'questionSetDynamicFormId';
export const QuestionSetDynamicForm = DynamicFormWithBox(
  questionSetDynamicFormId
);

export const onNavigate = (url) => {
  navigator.push(url, false);
};

export const QuestionSetFormInputKey = {
  NAME: 'name',
  SLUG: 'slug',
  MIN_PRICE: 'min_price',
  MAX_PRICE: 'max_price',
  STATUS: 'is_active',
  PRODUCT_GROUP: 'group',
  PRODUCT_CATEGORY: 'category',
};

export const datatableColumns = [
  {
    key: 0,
    dataIndex: 'sort_order',
    title: T('question.order'),
  },
  {
    key: 1,
    dataIndex: 'category',
    title: T('category'),
    render: (value) => {
      return value ? <h4>{value}</h4> : <h4>{T('all')}</h4>;
    },
  },
  {
    key: 2,
    dataIndex: 'content',
    title: T('content'),
    width: '50%',
  },
  {
    key: 3,
    dataIndex: 'description',
    title: T('description'),
    width: '50%',
  },
];
