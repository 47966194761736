import { changeableProductCategoryOptions } from '@constants/commontypes';
import { T } from '@utils/languageProvider';

export const questionType = [
  {
    pk: '0',
    value: 'imei',
    label: 'Imei',
  },
  {
    pk: '1',
    value: 'boolean',
    label: 'Boolean',
  },
  {
    pk: '2',
    value: 'contract_confirmation',
    label: 'Contract Confirmation',
  },
  {
    pk: '3',
    value: 'promotion',
    label: 'Promotion',
  },
  {
    pk: '4',
    value: 'serial_number',
    label: 'Serial Number',
  },
];

export const amountType = [
  {
    pk: '0',
    value: 'fixed',
    label: 'Fixed',
  },
  {
    pk: '1',
    value: 'percent',
    label: 'Percent',
  },
];

export const changeableProductCategoryOptionsWithAll = [
  ...changeableProductCategoryOptions,
  {
    pk: '3',
    label: T('all'),
    value: 'all',
  },
];
