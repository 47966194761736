import React from 'react';

import { T } from '@utils/languageProvider';
import NavigationCardList from '@components/NavigationCard/list';
import LayoutContentWrapper from '@components/utility/layoutWrapper';

const TradeInLanding = () => {
  const getNavigationList = () => {
    return [
      {
        link: '/trade-in/change-products/brands',
        title: T('brands'),
        text: T('tradein.brands.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
      {
        link: '/trade-in/change-products/series',
        title: T('changable.series'),
        text: T('tradein.series.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
      {
        link: '/trade-in/change-products/changeable-products',
        title: T('changeable.products'),
        text: T('changeable.products.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
      {
        link: '/trade-in/change-products/changeable-product-rules',
        title: T('changeable.product.rules'),
        text: T('changeable.product.rules.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
    ];
  };
  return (
    <LayoutContentWrapper className="module-landing">
      <NavigationCardList navigationList={getNavigationList()} />
    </LayoutContentWrapper>
  );
};

export default TradeInLanding;
