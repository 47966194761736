import React from 'react';

import { T } from '@utils/languageProvider';
import NavigationCardList from '@components/NavigationCard/list';
import LayoutContentWrapper from '@components/utility/layoutWrapper';

const TradeInLanding = () => {
  const getNavigationList = () => {
    return [
      {
        link: '/trade-in/change-products',
        title: T('change.products'),
        text: T('change.products.desc'),
        color: '#ffa154',
        intrinsic: 'icon-durum_transfer',
      },
      {
        link: '/trade-in/question-set',
        title: T('question.set'),
        text: T('question.sets.desc'),
        color: '#ffa154',
        intrinsic: 'icon-onay',
      },
      {
        link: '/trade-in/manage-products',
        title: T('manage.products'),
        text: T('manage.products.desc'),
        color: '#ffa154',
        intrinsic: 'icon-onay',
      },
    ];
  };
  return (
    <LayoutContentWrapper className="module-landing">
      <NavigationCardList navigationList={getNavigationList()} />
    </LayoutContentWrapper>
  );
};

export default TradeInLanding;
