import React from 'react';

import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';

import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { productsUrl } from '@constants/serviceUrls';

const ProductList = () => {
  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'id',
      name: T('id'),
      active: true,
      fieldProps: {
        placeholder: T('id'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'remote_identifier',
      name: T('code'),
      active: true,
      fieldProps: {
        placeholder: T('code'),
      },
    },
  ].map((filter, index) => ({
    ...filter,
    pk: index + 1,
  }));

  const columns = [
    {
      dataIndex: 'id',
      title: T('id'),
    },
    {
      dataIndex: 'remote_identifier',
      title: T('code'),
      render: (value) => <h1>{value}</h1>,
    },
    {
      dataIndex: 'group.name',
      title: T('product.groups'),
      render: (value) => (value ? <h1>{value}</h1> : <h1>None</h1>),
    },
  ].map((column, index) => ({
    ...column,
    key: index + 1,
  }));

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const onRowClick = (row) => {
    onNavigate(
      `/trade-in/manage-products/products/form/${row.remote_identifier}`
    );
  };

  return (
    <LayoutContentWrapper className="stock-lists">
      <DatatableWithFilter
        filterProps={{ filterInputs }}
        datatableProps={{
          rowKey: 'id',
          exportable: false,
          columns: columns,
          onRowClick: onRowClick,
          subtitle: T('products'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(''),
          url: productsUrl,
        }}
      />
    </LayoutContentWrapper>
  );
};

export default ProductList;
