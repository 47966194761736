import { readStorageItem } from '@common/storage';
// checkAuth parametresinin default olarak true dönmemizin sebebi utils>urlSync.js store oluşmadan options'ı çağırıyor.
const options = (checkAuth = () => true) => {
  const channelBasedVisibility = readStorageItem('channelType') === 'web';

  const optionList = [
    {
      key: 'trade-in/change-products',
      label: 'change.products',
      leftIcon: 'icon-urunler',
      navigateTo: '/trade-in/change-products',
      children: [
        {
          key: '/trade-in/change-products/changeable-products',
          label: 'changeable.products',
          leftIcon: 'ion-record',
        },
        {
          key: '/trade-in/change-products/changeable-product-rules',
          label: 'changeable.product.rules',
          leftIcon: 'ion-record',
        },
        {
          key: '/trade-in/change-products/brands',
          label: 'brands',
          leftIcon: 'ion-record',
        },
        {
          key: '/trade-in/change-products/series',
          label: 'changable.series',
          leftIcon: 'ion-record',
        },
      ],
    },
    // {
    //   key: 'trade-in/questions',
    //   label: 'questions',
    //   leftIcon: 'icon-dashboard',
    // },
    {
      key: 'trade-in/question-set',
      label: 'question.sets',
      leftIcon: 'icon-dashboard',
    },
    {
      key: 'trade-in/manage-products',
      label: 'manage.products',
      leftIcon: 'icon-dashboard',
      navigateTo: '/trade-in/manage-products',
      children: [
        {
          key: '/trade-in/manage-products/product-groups',
          label: 'product.groups',
          leftIcon: 'ion-record',
        },
        {
          key: '/trade-in/manage-products/products',
          label: 'products',
          leftIcon: 'ion-record',
        },
      ],
    },

    checkAuth(['dashboard', 'admin-v2-dashboard']) && {
      key: 'dashboard',
      label: 'sidebar.dashboard',
      leftIcon: 'icon-dashboard',
    },
    checkAuth('products-and-categories') && {
      key: 'products-and-categories',
      label: 'sidebar.product',
      leftIcon: 'icon-urunler',
      navigateTo: '/products-and-categories',
      showArrow: true,
      children: [
        checkAuth('product-pool') && {
          key: '/products-and-categories/products/product-pool',
          label: 'sidebar.product0',
          leftIcon: 'ion-record',
        },
        checkAuth('product-specifications') && {
          key: '/products-and-categories/product-specifications',
          label: 'sidebar.product1',
          leftIcon: 'ion-record',
        },
        checkAuth('specification-sets') && {
          key: '/products-and-categories/specification-sets',
          label: 'product.attribute.sets',
          leftIcon: 'ion-record',
        },
        checkAuth('catalogs') && {
          key: '/products-and-categories/catalogs',
          label: 'sidebar.product2',
          leftIcon: 'ion-record',
        },
        checkAuth('prices') && {
          key: '/products-and-categories/prices',
          label: 'sidebar.product3',
          leftIcon: 'ion-record',
        },
        checkAuth('stocks') && {
          key: '/products-and-categories/stocks',
          label: 'sidebar.product4',
          leftIcon: 'ion-record',
        },
        checkAuth('catalog-feeds') && {
          key: '/products-and-categories/catalog-feeds/',
          label: 'sidebar.product5',
          leftIcon: 'ion-record',
        },
        checkAuth('product-collections') && {
          key: '/products-and-categories/product-collections/',
          label: 'sidebar.product6',
          leftIcon: 'ion-record',
        },
      ],
    },
    checkAuth('order') && {
      key: 'order',
      label: 'sidebar.order',
      leftIcon: 'icon-siparis',
      navigateTo: '/order',
      children: [
        checkAuth('orders') && {
          key: '/order/orders',
          label: 'sidebar.order',
          leftIcon: 'ion-record',
        },
        checkAuth('cancellation-requests') && {
          key: '/order/cancellation-requests',
          label: 'sidebar.order1',
          leftIcon: 'ion-record',
        },
        checkAuth('cancellation-plan') && {
          key: '/order/cancellation-plan',
          label: 'sidebar.order3',
          leftIcon: 'ion-record',
        },
        checkAuth('transactions') && {
          key: '/order/transactions',
          label: 'sidebar.order2',
          leftIcon: 'ion-record',
        },
        checkAuth('omnitron-transactions') && {
          key: '/order/omnitron-transactions',
          label: 'cancelled.refunded.transactions',
          leftIcon: 'ion-record',
        },
      ],
    },
    checkAuth('customers') && {
      key: 'customers',
      navigateTo: '/customers',
      label: 'sidebar.customer',
      leftIcon: 'icon-musteriler',
    },
    checkAuth(['sales-channels', 'admin-v2-sales-channels']) && {
      key: 'sales-channels',
      label: 'sidebar.channels',
      leftIcon: 'icon-shop',
      children: [
        checkAuth('category-management') && {
          key: '/category-management',
          label: 'sidebar.category-management',
          leftIcon: 'ion-record',
        },
        checkAuth('products') && {
          key: '/products',
          label: 'products',
          leftIcon: 'ion-record',
        },
        checkAuth('shop-users') &&
          channelBasedVisibility && {
            key: '/shop-users',
            label: 'sidebar.users',
            leftIcon: 'ion-record',
          },
        checkAuth('marketing') &&
          channelBasedVisibility && {
            key: '/marketing',
            label: 'sidebar.marketing',
            leftIcon: 'ion-record',
          },
        checkAuth([
          'content-management',
          'admin-v2-sales-channels-content-management',
        ]) &&
          channelBasedVisibility && {
            key: '/content-management',
            label: 'content.management',
            leftIcon: 'ion-record',
          },
        checkAuth('payment-management') &&
          channelBasedVisibility && {
            key: '/payment-management',
            label: 'payment.management',
            leftIcon: 'ion-record',
          },
        checkAuth('sales-channel-settings') &&
          channelBasedVisibility && {
            key: '/sales-channel-settings',
            label: 'sidebar.channel-settings',
            leftIcon: 'ion-record',
          },
      ],
    },
    checkAuth('oms') && {
      key: 'oms',
      label: 'sidebar.oms',
      leftIcon: 'icon-oms',
      navigateTo: '/oms',
      children: [
        checkAuth('oms-dashboard') && {
          key: '/oms/dashboard',
          label: 'sidebar.dashboard',
          leftIcon: 'ion-record',
        },
        checkAuth('oms-orders') && {
          key: '/oms/orders',
          label: 'sidebar.oms1',
          leftIcon: 'ion-record',
        },
        checkAuth('package-orders') && {
          key: '/oms/package-orders',
          label: 'sidebar.oms2',
          leftIcon: 'ion-record',
        },
        checkAuth('transfer-orders') && {
          key: '/oms/transfer-orders',
          label: 'sidebar.oms3',
          leftIcon: 'ion-record',
        },
        checkAuth('location-movement') && {
          key: '/oms/location-movement',
          label: 'sidebar.oms4',
          leftIcon: 'ion-record',
        },
        checkAuth('transfer-location-movement') && {
          key: '/oms/transfer-location-movement',
          label: 'sidebar.oms5',
          leftIcon: 'ion-record',
        },
        checkAuth('stock-locations') && {
          key: '/oms/stock-locations',
          label: 'sidebar.oms6',
          leftIcon: 'ion-record',
        },
        checkAuth('scenarios') && {
          key: '/oms/scenarios',
          label: 'sidebar.oms11',
          leftIcon: 'ion-record',
        },
        checkAuth('oms-settings') && {
          key: '/oms/settings',
          label: 'sidebar.oms9',
          leftIcon: 'ion-record',
        },
      ],
    },
    checkAuth(['unified', 'admin-v2-unified']) && {
      key: 'unified',
      label: 'sidebar.unified',
      leftIcon: 'icon-unified',
      navigateTo: '/unified',
      children: [
        checkAuth('contracts') && {
          key: '/unified/contracts',
          label: 'unified.digital.contract',
          leftIcon: 'ion-record',
        },
        checkAuth('reconciliations') && {
          key: '/unified/reconciliations',
          label: 'unified.reconciliations',
          leftIcon: 'ion-record',
        },
        checkAuth('unified-settings') && {
          key: '/unified/settings',
          label: 'unified.reconciliations.settings',
          leftIcon: 'ion-record',
        },
        checkAuth('items') && {
          key: '/unified/items',
          label: 'unified.reconciliations.items',
          leftIcon: 'ion-record',
        },
      ],
    },
    checkAuth('integrations') && {
      key: 'integrations',
      label: 'sidebar.integration',
      leftIcon: 'icon-entegresyon',
      navigateTo: '/integrations',
      children: [
        checkAuth(['pre-products', 'integrations-pre-products']) && {
          key: '/integrations/pre-products',
          label: 'sidebar.integration0',
          leftIcon: 'ion-record',
        },
        checkAuth(['mapping', 'integrations-mapping']) && {
          key: '/integrations/mapping',
          label: 'sidebar.integration1',
          leftIcon: 'ion-record',
        },
        checkAuth(['service-logs', 'integrations-service-logs']) && {
          key: '/integrations/service-logs',
          label: 'sidebar.integration2',
          leftIcon: 'ion-record',
        },
        checkAuth('cargo-logs') && {
          key: '/integrations/cargo-logs',
          label: 'cargo.logs',
          leftIcon: 'ion-record',
        },
        checkAuth('location-mapping-logs') && {
          key: '/integrations/location-mapping-logs',
          label: 'mapping.logs',
          leftIcon: 'ion-record',
        },
      ],
    },
    checkAuth(['settings', 'admin-v2-settings']) && {
      key: 'settings',
      label: 'sidebar.settings',
      leftIcon: 'icon-ayarlar',
      navigateTo: '/settings',
      children: [
        checkAuth('setting-channels') && {
          key: '/settings/channels',
          label: 'sidebar.settings0',
          leftIcon: 'ion-record',
        },
        checkAuth('shipping-company') && {
          key: '/settings/shipping-company',
          label: 'sidebar.settings9',
          leftIcon: 'ion-record',
        },
        checkAuth('cancellation-reasons') && {
          key: '/settings/cancellation-reasons',
          label: 'cancel.refund.reasons',
          leftIcon: 'ion-record',
        },
        ...(checkAuth(['users', 'admin-v2-settings-users'])
          ? [
              {
                key: '/settings/users',
                label: 'users',
                leftIcon: 'ion-record',
              },
              checkAuth('general-permission') && {
                key: '/settings/general-permission',
                label: 'general.permission.group',
                leftIcon: 'ion-record',
              },
              checkAuth('catalog-permission') && {
                key: '/settings/catalog-permission',
                label: 'catalog.permission.group',
                leftIcon: 'ion-record',
              },
              checkAuth('channel-permission') && {
                key: '/settings/channel-permission',
                label: 'channel.permissions.groups',
                leftIcon: 'ion-record',
              },
            ]
          : []),
      ],
    },
  ];

  const filterItems = (items) => {
    return items
      .filter(Boolean)
      .map((item) =>
        item.children
          ? { ...item, children: item.children.filter(Boolean) }
          : item
      );
  };

  return filterItems(optionList);
};

export default options;
