const siteConfig = {
  siteName: 'Omnitron',
  footerText: `Omnitron ©${new Date().getFullYear()} Created by Akinon`,
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'turkish';
export { siteConfig, themeConfig, language };
