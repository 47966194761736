import {
  GET_BRANDS,
  GET_SERIES,
  GET_QUESTION_SUCCESS,
  GET_TRADABLEITEM_SUCCESS,
  GET_TRADE_RULE_SET,
  GET_QUESTION_SET_SUCCESS,
  GET_TRADABLEITEMLIST_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_GROUPS_SUCCESS,
  GET_PRODUCT_GROUP_SUCCESS,
  GET_SIMPLIFIED_PRODCUTS_SUCCESS,
  GET_BRAND_SUCCESS,
  GET_SINGLE_SERIES_SUCCESS,
  RESET_PRODUCT_GROUP,
} from '../actiontypes';

export const initialState = {
  question: {},
  tradableItem: {},
  brands: [],
  brand: {},
  series: [],
  singleSeries: {},
  tradeRuleSet: [],
  products: [],
  tradableItems: [],
  questionSet: {},
  product: [],
  productGroups: [],
  productGroup: {},
  simplifiedProducts: [],
};

export default function (state = initialState, action) {
  const result = action.payload && action.payload.results;

  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        brands: result || initialState.brands,
      };
    case GET_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload || initialState.brand,
      };
    case GET_SERIES:
      return {
        ...state,
        series: result || initialState.series,
      };
    case GET_SINGLE_SERIES_SUCCESS:
      return {
        ...state,
        singleSeries: action.payload || initialState.brand,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: result || initialState.products,
      };
    case GET_PRODUCT:
      return {
        ...state,
        product: action.payload || initialState.product,
      };
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        question: action.payload || initialState.question,
      };
    case GET_TRADABLEITEM_SUCCESS:
      return {
        ...state,
        tradableItem: action.payload || initialState.tradableItem,
      };
    case GET_TRADABLEITEMLIST_SUCCESS:
      return {
        ...state,
        tradableItems: result || initialState.tradableItems,
      };
    case GET_TRADE_RULE_SET:
      return {
        ...state,
        tradeRuleSet: action.payload || initialState.tradeRuleSet,
      };
    case GET_QUESTION_SET_SUCCESS:
      return {
        ...state,
        questionSet: action.payload || initialState.questionSet,
      };
    case GET_PRODUCT_GROUPS_SUCCESS:
      return {
        ...state,
        productGroups: action.payload || initialState.productGroups,
      };
    case GET_PRODUCT_GROUP_SUCCESS:
      return {
        ...state,
        productGroup: action.payload || initialState.productGroup,
      };
    case GET_SIMPLIFIED_PRODCUTS_SUCCESS:
      return {
        ...state,
        simplifiedProducts: result || initialState.simplifiedProducts,
      };
    case RESET_PRODUCT_GROUP:
      return { ...state, productGroup: initialState.productGroup };
    default:
      return state;
  }
}
