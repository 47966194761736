import { baseURLSet } from '@constants/serviceUrls';
import { addQueryToURL } from '@utils/';

export const downloadUrlAndParams = (params, isBaseURL = false) => {
  let { url } = params;
  delete params.url;
  if (params._fields) {
    let fields = typeof params._fields === 'string' ? params._fields.split(',') : params._fields;
    delete params._fields;
    if (params) {
      url = addQueryToURL(`${url}excel_export/`, params);

    } 
    fields.forEach((item) => {
      url += `&_fields=${item}`;
    });
  } else {
    url = addQueryToURL(`${url}`, params);
  }
  console.log('download url', url); //bu console.log'u ozellikle istediler. silmeyin.

  return { url: isBaseURL ? url : `${baseURLSet.default}${url}` };
};
