import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Button, Checkbox, InputNumber } from 'antd';
import Select, { SelectOption } from '@components/UIElements/Select';

import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import Box from '@components/utility/box/index';
import Block from '@components/Block';

import { boxFormItemLayout } from '@components/DynamicForm/constants';
import { T } from '@utils/languageProvider';
import { getQuestion, saveQuestion } from '@resources/actions/tradeIn';
import { validationTypes, trueFalseBoolOptions } from '@constants/commontypes';
import { navigator } from '@common/navigation';
import {
  questionType,
  amountType,
  changeableProductCategoryOptionsWithAll,
} from './common';

import './style.scss';

const { TextArea } = Input;

const QuestionsDetail = (props) => {
  const {
    getQuestion,
    question,
    saveQuestion,
    form: { getFieldDecorator },
  } = props;
  const params = useParams();
  const { questionSetID, id } = params;

  const [state, setState] = useState({
    loading: true,
    field_type: 'imei',
    amount_callback: 'fixed',
    category: null,
  });

  useEffect(() => {
    id
      ? getQuestion(id).then((value) => {
          question &&
            setState({
              ...state,
              loading: false,
              field_type: value?.field_type,
              amount_callback: value?.amount_callback,
              category: !value?.category ? 'all' : value?.category,
            });
        })
      : setState({
          ...state,
          loading: false,
        });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        const {
          amount_callback = 'fixed',
          true_amount_value = '0',
          false_amount_value = '0',
          ...restValues
        } = values;

        if (restValues.category === 'all') {
          restValues.category = null;
        }

        saveQuestion(id, {
          ...restValues,
          amount_callback,
          true_amount_value,
          false_amount_value,
          question_sets: [questionSetID],
        }).then(() =>
          onNavigate(`/trade-in/question-set/form/${questionSetID}`)
        );
      }
    });
  };

  const changeHandler = (key) => (value) => {
    setState({ ...state, [key]: value });
  };

  const amountTypeProps = {
    fixed: {
      formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      parser: (value) => value.replace(/\\s?|(,*)/g, ''),
    },
    percent: {
      min: 0,
      max: 100,
      formatter: (value) => `${value}%`,
      parser: (value) => value.replace('%', ''),
    },
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const defaultValue = id && question;

  return (
    <LayoutContentWrapper className="question-form" loading={state.loading}>
      <Box
        title={T('add.edit').format(T('question'))}
        subtitle={T('questions.desc')}
        className="box-primary form-box"
      >
        <Form onSubmit={handleSubmit} {...boxFormItemLayout}>
          <Form.Item label={T('content')}>
            {getFieldDecorator('content', {
              initialValue: defaultValue?.content,
              rules: [validationTypes.required(T('content'))],
            })(<TextArea placeholder={T('content')} />)}
          </Form.Item>
          <Form.Item label={T('description')}>
            {getFieldDecorator('description', {
              initialValue: defaultValue?.description,
            })(<TextArea placeholder={T('description')} />)}
          </Form.Item>
          <Form.Item label={T('slug')}>
            {getFieldDecorator('slug', {
              initialValue: defaultValue?.slug,
              rules: [validationTypes.required(T('slug'))],
            })(<Input placeholder={T('slug')} />)}
          </Form.Item>

          <Form.Item label={T('is.required')}>
            {getFieldDecorator('is_required', {
              initialValue: defaultValue?.is_required,
              rules: [validationTypes.required(T('is.required'))],
            })(
              <Select placeholder={T('is.required')}>
                {trueFalseBoolOptions.map((option) => {
                  return (
                    <SelectOption value={option.value}>
                      {option.label}
                    </SelectOption>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={T('type')}>
            {getFieldDecorator('field_type', {
              initialValue: defaultValue?.field_type || state.field_type,
              rules: [validationTypes.required(T('type'))],
            })(
              <Select
                placeholder={T("T('type')")}
                onChange={changeHandler('field_type')}
              >
                {questionType.map((option) => {
                  return (
                    <SelectOption value={option.value}>
                      {option.label}
                    </SelectOption>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          {state.field_type === 'boolean' && (
            <>
              <Form.Item label={T('amount.type')}>
                {getFieldDecorator('amount_callback', {
                  initialValue:
                    defaultValue?.amount_callback || state.amount_callback,
                  rules: [validationTypes.required(T('amount.type'))],
                })(
                  <Select
                    placeholder={T('amount.type')}
                    onChange={changeHandler('amount_callback')}
                  >
                    {amountType.map((option) => {
                      return (
                        <SelectOption value={option.value}>
                          {option.label}
                        </SelectOption>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label={T('amount.effect')}
                className="amount-value-wrapper"
              >
                <Form.Item label={T('yes')} className="amount-value">
                  {getFieldDecorator('true_amount_value', {
                    initialValue: defaultValue?.true_amount_value,
                    rules: [validationTypes.required(T('yes'))],
                  })(
                    <InputNumber
                      placeholder={T('yes')}
                      {...amountTypeProps[state.amount_callback]}
                    />
                  )}
                </Form.Item>
                <Form.Item label={T('no')} className="amount-value">
                  {getFieldDecorator('false_amount_value', {
                    initialValue: defaultValue?.false_amount_value,
                    rules: [validationTypes.required(T('no'))],
                  })(
                    <InputNumber
                      placeholder={T('no')}
                      {...amountTypeProps[state.amount_callback]}
                    />
                  )}
                </Form.Item>
              </Form.Item>
            </>
          )}

          <Form.Item label={T('question.order')}>
            {getFieldDecorator('sort_order', {
              initialValue: defaultValue?.sort_order,
              rules: [validationTypes.required(T('question.order'))],
            })(<InputNumber min={1} placeholder={T('question.order')} />)}
          </Form.Item>

          <Form.Item label={T('changeable.product.category')}>
            {getFieldDecorator('category', {
              initialValue: defaultValue?.category || state.category,
              rules: [validationTypes.required(T('type'))],
            })(
              <Select
                placeholder={T('category')}
                onChange={changeHandler('category')}
              >
                {changeableProductCategoryOptionsWithAll.map((option) => {
                  return (
                    <SelectOption value={option.value}>
                      {option.label}
                    </SelectOption>
                  );
                })}
              </Select>
            )}
          </Form.Item>
          <Block className="submit-btn-wrapper"></Block>
          <Form.Item label=" ">
            <Button className="submit-btn" type="primary" htmlType="submit">
              {T('save')}
            </Button>
          </Form.Item>
        </Form>
      </Box>
    </LayoutContentWrapper>
  );
};

const QuestionsForm = Form.create({ name: 'dynamic_rule' })(QuestionsDetail);

const mapStateToProps = (state) => {
  return {
    question: state.tradeInReducer.question,
  };
};
const mapDispatchToProps = {
  getQuestion,
  saveQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsForm);
