import { sendRequest } from '@common/network';
import { httpMethods } from '@constants/commontypes';
import { store } from '@resources/store';
import {
  GET_QUESTION_SUCCESS,
  GET_TRADABLEITEM_SUCCESS,
  GET_SERIES,
  GET_BRANDS,
  GET_TRADE_RULE_SET,
  GET_QUESTION_SET_SUCCESS,
  GET_TRADABLEITEMLIST_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_GROUPS_SUCCESS,
  GET_PRODUCT_GROUP_SUCCESS,
  GET_SIMPLIFIED_PRODCUTS_SUCCESS,
  GET_BRAND_SUCCESS,
  GET_SINGLE_SERIES_SUCCESS,
  RESET_PRODUCT_GROUP,
} from '../actiontypes';
import {
  brandUrl,
  seriesUrl,
  questionUrl,
  tradableItemUrl,
  tradaRuleSetUrl,
  productsUrl,
  questionSetUrl,
  bulkProductsUrl,
  productGroupsUrl,
  productProductGroupsUrl,
  simplifiedProductsUrl,
  productGroupsAdditionalDiscount,
} from '@constants/serviceUrls';
import { createURL, addQueryToURL } from '@utils/';

export function getQuestion(id) {
  return async () => {
    return sendRequest({
      url: id && createURL(questionUrl, id),
      onSuccess: (result) => {
        return {
          type: GET_QUESTION_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function saveQuestion(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(questionUrl, [id]) : questionUrl,
      method: id ? httpMethods.PUT : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function getTradableItem(id) {
  return async () => {
    return sendRequest({
      url: id && createURL(tradableItemUrl, id),
      params: { include_inactives: true },
      onSuccess: (result) => {
        return {
          type: GET_TRADABLEITEM_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function saveTradableItem(id, params) {
  return async () => {
    return sendRequest({
      url: id
        ? createURL(tradableItemUrl, [id], { include_inactives: true })
        : tradableItemUrl,
      method: id ? httpMethods.PUT : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function saveBulkProduct(params) {
  return async () => {
    return sendRequest({
      url: createURL(bulkProductsUrl),
      method: httpMethods.POST,
      params: { ...params },
    });
  };
}

export function getTradableItemList() {
  return async () => {
    return sendRequest({
      url: addQueryToURL(tradableItemUrl, { limit: 9999 }),
      onSuccess: (result) => {
        return {
          type: GET_TRADABLEITEMLIST_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function getProducts() {
  return async () => {
    return sendRequest({
      url: addQueryToURL(productsUrl, { limit: 9999 }),
      onSuccess: (result) => {
        return {
          type: GET_PRODUCTS,
          payload: result,
        };
      },
    });
  };
}

export function getProduct(code) {
  return async () => {
    return sendRequest({
      url: createURL(productsUrl, code),
      onSuccess: (result) => {
        return {
          type: GET_PRODUCT,
          payload: result,
        };
      },
    });
  };
}

export function saveProduct(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(productsUrl, [id]) : productsUrl,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function getBrands() {
  return async () => {
    return sendRequest({
      url: createURL(brandUrl),
      onSuccess: (result) => {
        return {
          type: GET_BRANDS,
          payload: result,
        };
      },
    });
  };
}

export function saveBrand(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(brandUrl, [id]) : brandUrl,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function getBrand(id) {
  return async () => {
    return sendRequest({
      url: createURL(brandUrl, [id]),
      onSuccess: (result) => {
        return {
          type: GET_BRAND_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function getSeries() {
  return async () => {
    return sendRequest({
      url: createURL(seriesUrl),
      onSuccess: (result) => {
        return {
          type: GET_SERIES,
          payload: result,
        };
      },
    });
  };
}

export function saveSeries(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(seriesUrl, [id]) : seriesUrl,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function getSingleSeries(id) {
  return async () => {
    return sendRequest({
      url: createURL(seriesUrl, [id]),
      onSuccess: (result) => {
        return {
          type: GET_SINGLE_SERIES_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function getTradeRuleSet(id) {
  return async () => {
    return sendRequest({
      url: id && createURL(tradaRuleSetUrl, id),
      onSuccess: (result) => {
        return {
          type: GET_TRADE_RULE_SET,
          payload: result,
        };
      },
    });
  };
}

export function getQuestionSet(id) {
  return async () => {
    return sendRequest({
      url: id && createURL(questionSetUrl, id),
      onSuccess: (result) => {
        return {
          type: GET_QUESTION_SET_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function saveQuestionSet(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(questionSetUrl, [id]) : questionSetUrl,
      method: id ? httpMethods.PUT : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function getAllProductGroups() {
  return async () => {
    return sendRequest({
      url: addQueryToURL(productGroupsUrl, { limit: 9999 }),
      onSuccess: (result) => {
        return {
          type: GET_PRODUCT_GROUPS_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function getProductGroup(id) {
  return async () => {
    return sendRequest({
      url: createURL(productGroupsUrl, id),
      method: httpMethods.GET,
      onSuccess: (result) => {
        return {
          type: GET_PRODUCT_GROUP_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export const resetProductGroup = () => {
  store.dispatch({ type: RESET_PRODUCT_GROUP });
};

export function saveProductGroup(id, params) {
  return async () => {
    return sendRequest({
      url: id ? createURL(productGroupsUrl, [id]) : productGroupsUrl,
      method: id ? httpMethods.PATCH : httpMethods.POST,
      params: { ...params },
    });
  };
}

export function deleteProductGroup(id) {
  return async () => {
    return sendRequest({
      url: createURL(productGroupsUrl, [id]),
      method: httpMethods.DELETE,
    });
  };
}

export function deleteProductGroupAdditionalDiscount(id) {
  return async () => {
    return sendRequest({
      url: createURL(productGroupsAdditionalDiscount.format(id)),
      method: httpMethods.DELETE,
    });
  };
}

export function addProductsToProductGroup(id, params) {
  return async () => {
    return sendRequest({
      url: createURL(productProductGroupsUrl.format(id)),
      method: httpMethods.POST,
      params: { ...params },
    });
  };
}

export function removeProductFromGroup(id) {
  const productsUrlWithId = productsUrl + '{0}/';
  return sendRequest({
    url: createURL(productsUrlWithId.format(id)),
    method: httpMethods.PATCH,
    params: { group: null },
  });
}

export function getSimplifiedProducts() {
  return async () => {
    return sendRequest({
      url: addQueryToURL(simplifiedProductsUrl, { limit: 9999 }),
      onSuccess: (result) => {
        return {
          type: GET_SIMPLIFIED_PRODCUTS_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function removeQuestion(id) {
  return async () => {
    return sendRequest({
      url: createURL(questionUrl, [id]),
      method: httpMethods.DELETE,
    });
  };
}

export function saveAdditionalDiscountLimits(id, params) {
  return async () => {
    return sendRequest({
      url: createURL(productGroupsAdditionalDiscount.format(id)),
      method: httpMethods.POST,
      params,
    });
  };
}
