import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { navigator } from '@common/navigation';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  activePassiveBoolOptions,
  formLayoutTypes,
  validationTypes,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { getSingleSeries, saveSeries } from '@resources/actions/tradeIn';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { inputRenderer } from '@utils/';
import { brandUrl } from '@constants/serviceUrls';
import { addQueryToURL } from '@utils/';

const DynamicForm = DynamicFormWithBox('stock-locations-detail');

function ProductGroupDetail({ singleSeries, getSingleSeries, saveSeries }) {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        id && (await getSingleSeries(id));
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const generateFormInputs = () => {
    const fieldInputs = [
      {
        pk: 1,
        name: T('name'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        fieldProps: {
          placeholder: T('enter.variable').format(T('name')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('name'))],
        },
      },
      {
        pk: 2,
        name: T('status'),
        key: 'is_active',
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBoolOptions,
        fieldProps: {
          placeholder: T('select.series.status'),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('status'))],
        },
      },
      {
        pk: 3,
        name: T('brand'),
        key: 'brand',
        data_type: { value: inputTypes.remoteselect.alias },
        default_value:
          singleSeries?.brand?.name && id ? singleSeries?.brand?.name : '',
        labelKeyName: 'name',
        valueKeyName: 'id',
        objectKey: 'results',
        showSearch: true,
        url: addQueryToURL(brandUrl, { limit: 9999 }),
        remote: true,
        fieldProps: {
          placeholder: T('enter.variable').format(T('brand')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('brand'))],
        },
      },
    ];

    return inputRenderer(fieldInputs, id, singleSeries);
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      await saveSeries(id, values);
    } finally {
      setSubmitting(false);
      onNavigate('/trade-in/change-products/series');
    }
  };

  const inputFields = singleSeries && generateFormInputs();

  return (
    <LayoutContentWrapper className="series-form" loading={loading}>
      <DynamicForm
        title={T('add.edit').format(T('series'))}
        subtitle={T('detail.desc').format(T('series'))}
        onSubmit={onSubmit}
        submitting={submitting}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    singleSeries: state.tradeInReducer.singleSeries,
  };
};

const mapDispatchToProps = {
  getSingleSeries,
  saveSeries,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupDetail);
