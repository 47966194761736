import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { navigator } from '@common/navigation';
import { inputTypes } from '@components/DynamicForm/constants';
import {
  activePassiveBoolOptions,
  formLayoutTypes,
  validationTypes,
} from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { getBrand, saveBrand } from '@resources/actions/tradeIn';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { inputRenderer } from '@utils/';

const DynamicForm = DynamicFormWithBox('stock-locations-detail');

function ProductGroupDetail({ brand, getBrand, saveBrand }) {
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  console.log(id);

  useEffect(() => {
    (async () => {
      try {
        id && (await getBrand(id));
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const generateFormInputs = () => {
    const fieldInputs = [
      {
        pk: 1,
        name: T('name'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        fieldProps: {
          placeholder: T('enter.variable').format(T('name')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('name'))],
        },
      },
      {
        pk: 2,
        name: T('status'),
        key: 'is_active',
        data_type: { value: inputTypes.select.alias },
        visible_values: activePassiveBoolOptions,
        fieldProps: {
          placeholder: T('select.brand.status'),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('status'))],
        },
      },
    ];

    return inputRenderer(fieldInputs, id, brand);
  };

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const onSubmit = async (values) => {
    try {
      setSubmitting(true);
      await saveBrand(id, values);
    } finally {
      setSubmitting(false);
      onNavigate('/trade-in/change-products/brands');
    }
  };

  const inputFields = brand && generateFormInputs();

  return (
    <LayoutContentWrapper className="brand-form" loading={loading}>
      <DynamicForm
        title={T('add.edit').format(T('brand'))}
        subtitle={T('detail.desc').format(T('brand'))}
        onSubmit={onSubmit}
        submitting={submitting}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
}

const mapStateToProps = (state) => {
  return {
    brand: state.tradeInReducer.brand,
  };
};

const mapDispatchToProps = {
  getBrand,
  saveBrand,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupDetail);
