import { httpMethods } from '@constants/commontypes';
import { sendRequest } from '@common/network';
import { createURL } from '@utils';
import {
  postProductCategoryBulkCreate,
  getProductCategoryBulkCreateStatus,
  orderTrackingNumberUpdateURL,
  orderTrackingNumberUpdateStatusURL,
  preProductsURL,
  priceListURL,
  getProductDetailURL,
  getStockListURL,
  channelsURL,
  usersByChannelURL,
  productSortingBulkUpdateURL,
  productSortingBulkUpdateStatusURL,
  deleteCatalogItemsBulkURL,
  postCatalogItemsURL,
  addCatalogItemsBulkURL,
  getProductRuleDetailURL
} from '@constants/serviceUrls';

export function addProductsOnCategory(params) {
  return async () => {
    return sendRequest({
      url: postProductCategoryBulkCreate,
      method: httpMethods.POST,
      params,
    });
  };
}

export function getProductsOnCategoryStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: getProductCategoryBulkCreateStatus.format(cache_key),
    });
  };
}

export function bulkPickAttributeForSelectedProducts(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: createURL(preProductsURL, ['perform_bulk_update']),
      method: httpMethods.PATCH,
    });
  };
}

export function getPreProductStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(preProductsURL, [cache_key, 'bulk_update_status']),
    });
  };
}

export function addShippingCode(params) {
  return async () => {
    return sendRequest({
      url: orderTrackingNumberUpdateURL,
      method: httpMethods.POST,
      params,
    });
  };
}

// export function getShippingCodeStatus(cache_key) {
//   return async () => {
//     return sendRequest({
//       url: addQueryToURL(orderTrackingNumberUpdateStatusURL, { key: cache_key }),
//     });
//   };
// }

export function postPriceBulkUpdate(channel, params) {
  return async () => {
    return sendRequest({
      url: createURL(priceListURL, [channel, 'excel_import']),
      method: httpMethods.POST,
      params,
    });
  };
}

export function getPriceBulkStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(priceListURL, [cache_key, 'excel_import_status']),
    });
  };
}

export function updateBulkProduct(params) {
  return async () => {
    return sendRequest({
      url: createURL(getProductDetailURL),
      method: httpMethods.POST,
      params,
    });
  };
}

export function updateBulkRuleProduct(params) {
  return async () => {
    return sendRequest({
      url: createURL(getProductRuleDetailURL),
      method: httpMethods.POST,
      params,
    });
 };
}

export function getBulkProductStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(getProductDetailURL, [cache_key, 'bulk_update_status']),
    });
  };
}
export function getBulkProductRuleStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(getProductRuleDetailURL, [cache_key, 'bulk_update_status']),
    });
  };
}
export function postStockBulkUpdate(channel, params) {
  return async () => {
    return sendRequest({
      url: createURL(getStockListURL, [channel, 'excel_import']),
      method: httpMethods.POST,
      params,
    });
  };
}

export function getStockBulkStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(getStockListURL, [cache_key, 'excel_import_status']),
    });
  };
}

export function postChannelIntegrationBulkUpdate(channel, params) {
  return async () => {
    return sendRequest({
      url: createURL(channelsURL, [channel, 'bulk_update_integration_action']),
      method: httpMethods.POST,
      params,
    });
  };
}

export function getChannelIntegrationBulkStatus(cacheKey) {
  return async () => {
    return sendRequest({
      url: createURL(channelsURL, [cacheKey, 'bulk_update_integration_action_status']),
      method: httpMethods.GET,
    });
  };
}

export function updateBulkShopUser(channel, params) {
  return async () => {
    return sendRequest({
      url: createURL(usersByChannelURL.format(channel), ['bulk_update_attributes']),
      method: httpMethods.POST,
      params,
    });
  };
}

export function getBulkShopUserStatus(channel, cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(usersByChannelURL.format(cache_key), [
        channel,
        'bulk_update_attributes_status',
      ]),
    });
  };
}

export function updateProductSorting(params) {
  return async () => {
    return sendRequest({
      url: productSortingBulkUpdateURL,
      method: httpMethods.PATCH,
      params,
    });
  };
}

export function getProductSortingStatus(cache_key) {
  return async () => {
    return sendRequest({
      url: createURL(productSortingBulkUpdateStatusURL.format(cache_key)),
      method: httpMethods.GET,
    });
  };
}

export function addOrDeleteAllProducts(addAll = null, paramsArr) {
  return async () => {
    return sendRequest({
      url: addAll ? addCatalogItemsBulkURL : deleteCatalogItemsBulkURL,
      method: httpMethods.POST,
      params: addAll ? paramsArr : { catalog_items: paramsArr },
    });
  };
}

export function addOrDeleteBulkStatus(cache_key, addAll = null) {
  return async () => {
    return sendRequest({
      url: addAll
        ? `${postCatalogItemsURL}${cache_key}/bulk_create_status/`
        : `${postCatalogItemsURL}${cache_key}/bulk_delete_status/`,
    });
  };
}
