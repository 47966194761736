import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { inputTypes } from '@components/DynamicForm/constants';
import { formLayoutTypes, validationTypes } from '@constants/commontypes';
import { T } from '@utils/languageProvider';
import { productGroupsUrl } from '@constants/serviceUrls';
import { getProduct, saveProduct } from '@resources/actions/tradeIn';
import { DynamicFormWithBox } from '@components/DynamicForm';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { addQueryToURL } from '@utils/';
import { sendRequest } from '@common/network';
import { inputRenderer } from '@utils/';

const DynamicForm = DynamicFormWithBox('stock-locations-detail');

const ProductsDetail = (props) => {
  const { getProduct, product, saveProduct } = props;
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);
  const [savingProduct, setSavingProduct] = useState(false);
  const [productGroups, setProductGroups] = useState({});

  useEffect(() => {
    (async () => {
      try {
        await getProduct(id);
        const result = await sendRequest({
          url: addQueryToURL(productGroupsUrl, { limit: 9999 }),
        });
        setProductGroups({
          ...result,
          results: [
            ...result.results,
            {
              id: 0,
              name: 'None',
            },
          ],
        });
      } catch (err) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const generateFormInputs = () => {
    const fieldInputs = [
      {
        pk: 1,
        name: T('code'),
        key: 'remote_identifier',
        data_type: { value: inputTypes.input.alias },
        fieldOptions: {
          rules: [validationTypes.required(T('code'))],
        },
        fieldProps: {
          disabled: true,
          placeholder: T('enter.variable').format(T('code')),
        },
      },
      {
        pk: 2,
        name: T('category'),
        key: 'category',
        data_type: { value: inputTypes.input.alias },
        fieldProps: {
          disabled: true,
          placeholder: T('enter.variable').format(T('category')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('category'))],
        },
      },
      {
        pk: 3,
        name: T('name'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        fieldProps: {
          placeholder: T('enter.variable').format(T('name')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('name'))],
        },
      },
      {
        pk: 4,
        name: T('product.groups'),
        key: 'group',
        data_type: { value: inputTypes.remoteselect.alias },
        dataSource: productGroups,
        objectKey: 'results',
        labelKeyName: 'name',
        valueKeyName: 'id',
        placeholder: T('enter.variable').format(T('product.groups')),
        fieldProps: {
          defaultValue: product?.group?.id?.toString() || '0',
        },
      },
    ];

    return inputRenderer(fieldInputs, id, product);
  };

  const onSubmit = async (values) => {
    setSavingProduct(true);

    let { name, group } = values;
    if (group === '0') {
      group = null;
    }

    try {
      if (isNaN(group)) {
        await saveProduct(id, { name });
      } else {
        await saveProduct(id, { name, group });
      }
    } catch (err) {
    } finally {
      setSavingProduct(false);
    }
  };

  const inputFields = product && generateFormInputs();

  return (
    <LayoutContentWrapper
      className="changeable-products-form"
      loading={loading}
    >
      <DynamicForm
        title={T('product') + ' ' + T('edit')}
        subtitle={T('tradein.products.desc')}
        onSubmit={onSubmit}
        submitting={savingProduct}
        inputs={inputFields}
        layoutType={formLayoutTypes.boxLayout}
        objectKey="pk"
      />
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    product: state.tradeInReducer.product,
  };
};
const mapDispatchToProps = {
  getProduct,
  saveProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDetail);
