import { sendRequest } from '@common/network';
import { httpMethods } from '@constants/commontypes';
import {
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_FINAL,
  CHECK_AUTHORIZATION_SUCCESS,
  CHECK_AUTHORIZATION_FAIL,
  CHECK_AUTHORIZATION_FINAL,
  LOGOUT_SUCCESS,
  SET_TOKEN_FROM_STORAGE,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FINALLY,
  RESET_PASSWORD_SUCCESS,
  CONFIRM_PASSWORD_SUCCESS,
} from '../actiontypes';
import {
  loginURL,
  checkAuthorizationURL,
  logoutURL,
  getPermissionsURL,
  passwordResetURL,
  passwordConfirmURL,
} from '@constants/serviceUrls';
import { tokenStorageKey } from '@constants';
import { readStorageItem, removeStorageItem } from '@common/storage';
import { store } from '../store';
import { addQueryToURL } from '@utils/index';

export function setTokenFromStorage() {
  const token = readStorageItem(tokenStorageKey);
  return new Promise((resolve, reject) => {
    store.dispatch({ type: SET_TOKEN_FROM_STORAGE, payload: token });
    if (token) resolve();
    else reject();
  });
}

export function login(params = {}) {
  return async () => {
    return sendRequest({
      params,
      url: loginURL,
      method: httpMethods.POST,
      onBegin: () => {
        return {
          type: FETCH_USER_BEGIN,
        };
      },
      onSuccess: (result) => {
        return {
          type: FETCH_USER_SUCCESS,
          payload: result,
        };
      },
      onFail: (error) => {
        removeStorageItem(tokenStorageKey);
        return {
          type: FETCH_USER_FAIL,
          payload: error,
        };
      },
      onFinally: () => {
        return {
          type: FETCH_USER_FINAL,
        };
      },
    });
  };
}

export function checkAuthorization() {
  return async () => {
    return authorizationRequest();
  };
}

export function authorizationRequest() {
  return sendRequest({
    url: checkAuthorizationURL,
    onSuccess: (result) => {
      return {
        type: CHECK_AUTHORIZATION_SUCCESS,
        payload: result,
      };
    },
    onFail: () => {
      removeStorageItem(tokenStorageKey);
      return {
        type: CHECK_AUTHORIZATION_FAIL,
      };
    },
    onFinally: () => {
      return {
        type: CHECK_AUTHORIZATION_FINAL,
      };
    },
  });
}

export function logout(params = {}) {
  removeStorageItem(tokenStorageKey);
  return async (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
  // return async () => {
  //   return sendRequest({
  //     params,
  //     url: logoutURL,
  //     method: httpMethods.POST,
  //     useDefaultBaseURL: true,
  //     onSuccess: result => {
  //       return {
  //         type: LOGOUT_SUCCESS,
  //         payload: result
  //       };
  //     }
  //   });
  // };
}

export function getPermissions(userID) {
  return async () => {
    return sendRequest({
      url: addQueryToURL(getPermissionsURL, {
        user_pk: userID,
        root: 'admin-v2',
      }),
      onSuccess: (result) => {
        return {
          type: GET_PERMISSIONS_SUCCESS,
          payload: result,
        };
      },
      onFinally: () => {
        return {
          type: GET_PERMISSIONS_FINALLY,
        };
      },
    });
  };
}

export function sendResetPasswordLink(params) {
  return async () => {
    return sendRequest({
      url: passwordResetURL,
      method: httpMethods.POST,
      params,
      onSuccess: (result) => {
        return {
          type: RESET_PASSWORD_SUCCESS,
          payload: result,
        };
      },
    });
  };
}

export function confirmPassword(params) {
  return async () => {
    return sendRequest({
      url: passwordConfirmURL,
      method: httpMethods.POST,
      params,
      onSuccess: (result) => {
        return {
          type: CONFIRM_PASSWORD_SUCCESS,
          payload: result,
        };
      },
    });
  };
}
