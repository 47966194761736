import React, { useState, useEffect } from 'react';
import { navigator } from '@common/navigation';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';

import { setActionElements } from '@common';
import { bulkFileUpdate } from '@utils/';
import Select, { SelectOption } from '@components/UIElements/Select';

import { T } from '@utils/languageProvider';
import {
  Form,
  Input,
  Cascader,
  Row,
  Col,
  Table,
  Button,
  Tabs,
  Modal,
} from 'antd';

import {
  getTradeRuleSet,
  getProducts,
  getProduct,
  getTradableItemList,
  getBrands,
} from '@resources/actions/tradeIn';

import Box from '@components/utility/box';
import {
  bulkProductsUrl,
  tradaRuleSetUrl,
  removeTradebleItemURL,
  tradableItemAmountURL,
  patchProductSpecURL,
} from '@constants/serviceUrls';
import {
  changeableProductCategoryOptions,
  httpMethods,
  validationTypes,
} from '@constants/commontypes';
import { sendRequest } from '@common/network';
import { createURL, addQueryToURL } from '@utils/';
import { inputTypes } from '@components/DynamicForm/constants';
import DatatableWithFilter from '@components/DatatableWithFilter';
import { logResponseMessages } from '@common';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import {
  updateBulkRuleProduct,
  getBulkProductRuleStatus,
} from '@resources/actions/bulkUpdates';
import Block from '@components/Block';
import { DraggerFileInput } from '@components/FileInput';
import { allowedFileTypes } from '@constants/';

import './style.scss';

const ChangeableProductRulesDetail = (props) => {
  const { getFieldDecorator, validateFields } = props.form;
  const {
    getTradeRuleSet,
    getTradableItemList,
    tradableItems,
    brands,
    getBrands,
    getProduct,
    updateBulkRuleProduct,
    getBulkProductRuleStatus,
  } = props;

  const params = useParams();
  let { id } = params;
  if (id) {
    id = decodeURIComponent(id);
  }

  const [tradeableItemsMap, setTradeableItemsMap] = useState({});
  const [productSelect, setProductSelect] = useState();
  const [codes, setCodes] = useState();
  const [tableDatas, setTableDatas] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);
  const [initialProductCode, setInitialProductCode] = useState();
  const [initialMinPrice, setInitialMinPrice] = useState();
  const [initialMaxPrice, setInitialMaxPrice] = useState();
  const [tradeRuleSetId, setTradeRuleSetId] = useState();
  const [productCategory, setProductCategory] = useState();
  const [visibleModal, setVisibleModal] = useState();
  const [tradableTableItems, setTradableTableItems] = useState([]);
  const [selectedDataIds, setSelectedDataIds] = useState([]);
  const [amount, setAmount] = useState();
  const [selectOption, setSelectOption] = useState('fixed');
  const [loading, setLoading] = useState(true);
  const [showUpdateProductInput, setShowUpdateProductInput] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [dataTableLoading, setDataTableLoading] = useState(false);
  const [productUpdateDisabled, setProductUpdateDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  useEffect(() => {
    getTradableItemList().then((res) => {
      const tradeableItemMapHolder = {};
      res.results.forEach((item) => {
        tradeableItemMapHolder[item.id] = {
          seriesId: item.series.id,
          brandId: item.series.brand.id,
        };
        setTradeableItemsMap(tradeableItemMapHolder);
      });
    });

    getBrands();
    setActionElements([]);
    if (id) {
      getProduct(id).then((res) => {
        setInitialMinPrice(res.trade_ruleset.min_price);
        setInitialMaxPrice(res.trade_ruleset.max_price);
        setInitialProductCode(res.remote_identifier);
        setTradeRuleSetId(res.trade_ruleset.id);
        setProductCategory(res.category);
        const initialItems = res.trade_ruleset.tradable_items.map((item) => {
          return [item.name, item.amount, item.id];
        });
        setTableDatas(initialItems);
        setTradableTableItems(res?.trade_ruleset?.tradable_items);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }

    return () => setActionElements([]);
  }, []);

  const onSubmitChangeRuleSet = (event) => {
    event.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        setDataTableLoading(true);
        const tableDatasMapper = tableDatas.map((item) => item[2]);
        if (codes) {
          let params = {
            remote_identifiers: codes,
            category: values.category,
            trade_ruleset: {
              min_price: values.min_price,
              max_price: values.max_price,
              tradable_items: tableDatasMapper,
            },
          };
          sendRequest({
            url: createURL(bulkProductsUrl),
            method: httpMethods.POST,
            params: { ...params },
          }).then(() => {
            setProductSelect();
            setCodes();
            setTableDatas([]);
            setDataTableLoading(false);
          });
          if (id) {
            sendRequest({
              url: createURL(
                tradaRuleSetUrl,
                `${tradeRuleSetId.toString()}${tradableItemAmountURL}`
              ),
              method: httpMethods.POST,
              params: {
                tradable_items: tableDatasMapper.map((item) => {
                  return {
                    tradable_item: item,
                    amount: 0,
                    amount_type: 'fixed',
                  };
                }),
              },
            });
          }
        } else {
          let params = {
            min_price: values.min_price == '' ? null : values.min_price,
            max_price: values.max_price == '' ? null : values.max_price,
            tradable_items: tableDatasMapper.map((item) => {
              return {
                tradable_item: item,
                amount: 0,
                amount_type: 'fixed',
              };
            }),
          };
          sendRequest({
            url: createURL(tradaRuleSetUrl, tradeRuleSetId.toString()),
            method: httpMethods.PUT,
            params: { ...params },
          });
          sendRequest({
            url: createURL(
              tradaRuleSetUrl,
              `${tradeRuleSetId.toString()}${tradableItemAmountURL}`
            ),
            method: httpMethods.POST,
            params: { tradable_items: params.tradable_items },
          }).then((res) =>
            navigator.push(
              `/trade-in/change-products/changeable-product-rules/form/${encodeURIComponent(
                id
              )}`
            )
          );
        }
      }
    });
  };

  const onSubmitChangePrice = () => {
    if (!amount) {
      logResponseMessages({
        type: 'warning',
        message: T('enter.percentage.price.range'),
      });
      setVisibleModal(false);
    } else {
      let tradableItems = [];
      if (selectedDataIds.length === 0) {
        tradableItems = tradableTableItems.map((item) => {
          return {
            tradable_item: item.id,
            amount: amount,
            amount_type: selectOption,
          };
        });
      } else {
        tradableItems = selectedDataIds.map((item) => {
          return {
            tradable_item: item,
            amount: amount,
            amount_type: selectOption,
          };
        });
      }

      changeItemPrices(tradableItems);
    }
  };

  const changeItemPrices = (tradableItems) => {
    sendRequest({
      url: createURL(
        tradaRuleSetUrl,
        `${tradeRuleSetId.toString()}${tradableItemAmountURL}`
      ),
      method: httpMethods.POST,
      params: { tradable_items: tradableItems },
      onBegin: () => {
        setLoading(true);
      },
      onSuccess: (result) => {
        setVisibleModal(false);
      },
      onFail: (result) => {
        setVisibleModal(false);
        setLoading(false);
        logResponseMessages({ type: 'error', message: T('unsuccessful') });
      },
      onFinally: () => {
        setLoading(false);
      },
    });
  };

  const addRow = () => {
    if (productSelect) {
      if (productSelect[0].length == 1) {
        const series = tradableItems.filter(
          (item) => item.series.brand.name == productSelect[0][0]
        );
        const params = series.map((item) => {
          let duplicatedItems = tableDatas.find((data) => data[0] == item.id);
          return { 0: item.name, 2: item.id, 3: duplicatedItems };
        });
        const uniqueItems = params.filter((item) => !item[3]);
        let migrateTables = [...uniqueItems, ...tableDatas];
        setTableDatas([...migrateTables]);
      }
      if (productSelect[0].length == 2) {
        const series = tradableItems.filter(
          (item) => item.series.name == productSelect[0][1]
        );
        const params = series.map((item) => {
          let duplicatedItems = tableDatas.find((data) => data[0] == item.id);
          return { 0: item.name, 2: item.id, 3: duplicatedItems };
        });
        const uniqueItems = params.filter((item) => !item[3]);
        let migrateTables = [...uniqueItems, ...tableDatas];
        setTableDatas([...migrateTables]);
      }
      if (productSelect[0].length == 3) {
        const findProducts = productSelect.map((item) => item[2]);
        const idProduct = tradableItems.find((item) =>
          findProducts.includes(item.name)
        )?.id;
        Object.assign(productSelect, { 0: { 0: findProducts, 2: idProduct } });
        let migrateTables = [...productSelect, ...tableDatas];
        let findDuplicatedDatas = tableDatas?.find(
          (item) => item[0] == productSelect[0][0]
        );
        !findDuplicatedDatas && setTableDatas([...migrateTables]);
      }
    }
  };

  const rowSelection = {
    selectedRowKeys: checkedRows,
    onChange: (rowKeys) => {
      setCheckedRows(rowKeys);
    },
  };

  const deleteTableDatas = () => {
    const checkedDatas = checkedRows.map((index) => tableDatas[index]);
    const tableDatasMinusCheckedDatas = tableDatas.filter(
      (data) => !checkedDatas.includes(data)
    );

    const requestDeleteMapper = checkedRows.map(
      (index) => tableDatas[index][2]
    );

    if (id) {
      handleTableDataDeleteWithId(
        tableDatasMinusCheckedDatas,
        requestDeleteMapper
      );
    } else {
      setTableDatas(tableDatasMinusCheckedDatas);
      setCheckedRows([]);
    }
  };

  const handleTableDataDeleteWithId = (
    tableDatasMinusCheckedDatas,
    requestDeleteMapper
  ) => {
    setDataTableLoading(true);
    sendRequest({
      url: createURL(
        tradaRuleSetUrl,
        `${tradeRuleSetId.toString()}${removeTradebleItemURL}`
      ),
      method: httpMethods.POST,
      params: { tradable_items: requestDeleteMapper },
    }).then((_) => {
      setCheckedRows([]);
      setDataTableLoading(false);
      setTableDatas(tableDatasMinusCheckedDatas);
    });
  };

  const tradebleItemMapper = tradableItems?.map((item) => {
    return {
      brand: brands.find((brand) => brand.id == item.series.brand.id)?.name,
      series: item.series.name,
      item: item.name,
    };
  });

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  let result = _.chain(tradebleItemMapper)
    .groupBy('brand')
    .map(function (v, i) {
      return {
        value: i,
        label: i,
        children: _.chain(v)
          .groupBy('series')
          .map(function (x, y) {
            return {
              value: y,
              label: y,
              children: _.chain(x)
                .groupBy('item')
                .map(function (a, b) {
                  return {
                    value: b,
                    label: b,
                  };
                })
                .value(),
            };
          })
          .value(),
      };
    })
    .value();

  function handleCodeChange(value) {
    setCodes(value);
  }

  const filterInputs = [
    {
      pk: 0,
      data_type: { value: inputTypes.input.alias },
      key: 'name',
      name: T('model'),
      active: true,
      fieldProps: {
        placeholder: T('model'),
      },
    },
  ];

  const columns = [
    {
      key: 0,
      dataIndex: 'name',
      title: T('model'),
    },
    {
      key: 1,
      dataIndex: 'amount',
      title: T('highestValue'),
    },
    {
      key: 2,
      dataIndex: 'price',
      title: T('original.price'),
    },
  ];

  const onChangeActiveFilters = (prop) => {
    if (prop.name == undefined) {
      sendRequest({
        url: addQueryToURL(patchProductSpecURL.format(initialProductCode)),
        onSuccess: (res) => {
          setTradableTableItems(res?.trade_ruleset?.tradable_items);
        },
      });
    } else {
      let denemeItemslar = tradableTableItems.filter((item) =>
        item.name.toLowerCase().includes(prop.name.toLowerCase())
      );
      setTradableTableItems(denemeItemslar);
    }
  };

  const datatableRowSelection = {
    onRowSelection: (selectedRowKeys) => {
      setSelectedDataIds(selectedRowKeys);
    },
  };

  const actionButtons = [
    {
      id: 0,
      reloadData: true,
      selectionKey: 'id',
      selectionRequired: true,
      label: T('confirm.selected.items'),
      customAction: () => setVisibleModal(true),
    },
    {
      id: 2,
      reloadData: true,
      selectionRequired: false,
      label: T('confirm.all'),
      customAction: () => setVisibleModal(true),
    },
  ];

  const handleBulkProductUpdate = async (file, fileList) => {
    setIsUploading(true);
    let bodyFormData = new FormData();
    fileList.forEach((file) => {
      bodyFormData.append('filename', file);
    });
    setProductUpdateDisabled(true);
    await bulkFileUpdate(
      {
        bulkAction: updateBulkRuleProduct,
        cacheKeyAction: getBulkProductRuleStatus,
        bulkActionArgs: [bodyFormData],
        cacheKeyActionName: 'getBulkProductRuleStatus',
      },
      (res) => {
        const { completed } = res;
        completed && setProductUpdateDisabled(true);
      }
    );
    setIsUploading(false);
    return false;
  };

  const toggleUpdateProduct = () =>
    setShowUpdateProductInput((prevState) => !prevState);

  return (
    <LayoutContentWrapper loading={loading}>
      <Box className={'akn-form'}>
        <Tabs
          defaultActiveKey="1"
          onChange={handleTabChange}
          activeKey={activeTab}
          className={'changable-product-rules-detail-tab-pan'}
        >
          <Tabs.TabPane tab={T('changeable.product.rules')} key="1">
            <Form onSubmit={(event) => onSubmitChangeRuleSet(event)}>
              <Row gutter={24}>
                <Col span={id ? 12 : 24}>
                  <Form.Item
                    label={T('code')}
                    className={'akn-form-item'}
                    required
                  >
                    {getFieldDecorator('remote_identifier', {
                      initialValue: id && initialProductCode,
                      setFieldsValue: codes,
                    })(
                      <Select
                        mode="tags"
                        className={'akn-select'}
                        allowClear
                        placeholder={T('please.select.sku')}
                        onChange={handleCodeChange}
                        disabled={id}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label={T('lowest.price')}
                    className={'akn-form-item'}
                  >
                    {getFieldDecorator('min_price', {
                      initialValue: id && initialMinPrice,
                    })(
                      <Input
                        className={'akn-input'}
                        placeholder={T('lowest.price')}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={T('highest.price')}
                    className={'akn-form-item'}
                  >
                    {getFieldDecorator('max_price', {
                      initialValue: id && initialMaxPrice,
                    })(
                      <Input
                        className={'akn-input'}
                        placeholder={T('highest.price')}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label={T('series.model')}
                    className="akn-form-item"
                  >
                    <span style={{ display: 'flex' }}>
                      <Cascader
                        className="akn-cascader"
                        onChange={(selectValue) =>
                          setProductSelect([selectValue])
                        }
                        options={result}
                        changeOnSelect
                      />
                      <Button className="akn-plus-button" onClick={addRow}>
                        {T('add')}
                      </Button>
                    </span>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={T('category')} className="akn-form-item">
                    <span style={{ display: 'flex' }}>
                      {getFieldDecorator('category', {
                        initialValue: id && productCategory,
                        rules: [validationTypes.required(T('is.required'))],
                      })(
                        <Select
                          placeholder={T('category')}
                          className="akn-select"
                          disabled={id}
                        >
                          {changeableProductCategoryOptions.map((option) => {
                            return (
                              <SelectOption value={option.value}>
                                {option.label}
                              </SelectOption>
                            );
                          })}
                        </Select>
                      )}
                    </span>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  label="Seçilenler"
                  className={'akn-form-item'}
                  required
                >
                  {getFieldDecorator('tradable_items')(
                    <>
                      <Table
                        loading={dataTableLoading}
                        rowSelection={rowSelection}
                        scroll={{ y: 300 }}
                        pagination={{ pageSize: 1000 }}
                        bordered
                        columns={[
                          {
                            title: T('model'),
                            dataIndex: '0',
                            key: '0',
                            width: 'max-content',
                            render: (modelName) => <a>{modelName}</a>,
                          },
                          {
                            title: T('price'),
                            dataIndex: '1',
                            key: '1',
                            render: (price) => <h3>{price}</h3>,
                          },
                          {
                            title: 'Model_Id',
                            dataIndex: '2',
                            key: '2',
                            render: (id) => <h3>{id}</h3>,
                          },
                          {
                            title: 'Brand_Id',
                            dataIndex: '2',
                            key: '3',
                            render: (id) => (
                              <h3>
                                {tradeableItemsMap &&
                                  tradeableItemsMap[id]?.brandId}
                              </h3>
                            ),
                          },
                          {
                            title: 'Series_Id',
                            dataIndex: '2',
                            key: '4',
                            render: (id) => (
                              <h3>
                                {tradeableItemsMap &&
                                  tradeableItemsMap[id]?.seriesId}
                              </h3>
                            ),
                          },
                        ]}
                        dataSource={tableDatas}
                      />
                      <Button
                        onClick={deleteTableDatas}
                        type="primary"
                        danger
                        disabled={!checkedRows}
                      >
                        {T('delete.selected.items')}
                      </Button>
                    </>
                  )}
                </Form.Item>
              </Row>
              <Row>
                <Col span={12}>
                  <Button type="primary" htmlType="submit">
                    {T('confirm')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>

          {id && (
            <Tabs.TabPane
              tab={T('changeable.product.rules.price.change')}
              key="2"
            >
              <Form.Item
                label={T('add.price')}
                className={'akn-form-item'}
                required
              >
                {getFieldDecorator('amount')(
                  <Input
                    className={'akn-select'}
                    addonBefore={getFieldDecorator('amount_type', {
                      initialValue: 'fixed',
                    })(
                      <Select
                        className="select-before"
                        onChange={(e) => setSelectOption(e)}
                      >
                        <SelectOption value="fixed">
                          {T('fixed_price.discount')}
                        </SelectOption>
                        <SelectOption value="percent">
                          (%) {T('percent')}
                        </SelectOption>
                      </Select>
                    )}
                    placeholder={T('enter.percentage.price.range')}
                    defaultValue="percent"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                )}
              </Form.Item>

              <Row gutter={24}>
                <Col span={24}>
                  <LayoutContentWrapper
                    className="mapping-page-wrapper"
                    loading={loading}
                  >
                    <Block className="deneme">
                      <Button
                        className="product-update-button"
                        type="primary"
                        onClick={toggleUpdateProduct}
                      >
                        {T('bulk.product.update')}
                      </Button>
                    </Block>
                    {showUpdateProductInput && (
                      <>
                        <Block className="excel-update-panel">
                          <Block className="col col-start">
                            <Block className="excel-update-panel__title">
                              {T('bulk.product.update')}
                            </Block>
                          </Block>

                          <Block className="col w-100">
                            <p className="w-100">
                              {T('page.pool.upload.desc')}
                            </p>
                            <Block
                              className="file-input-container"
                              loading={isUploading}
                            >
                              <DraggerFileInput
                                fileList={[]}
                                accept={allowedFileTypes}
                                beforeUpload={handleBulkProductUpdate}
                                singleAction
                                multiple={true}
                              />
                            </Block>
                          </Block>
                        </Block>
                      </>
                    )}
                    <DatatableWithFilter
                      filterProps={{
                        staticFilters: filterInputs,
                        filterViaFile: true,
                        getFiltersValue: onChangeActiveFilters,
                      }}
                      datatableProps={{
                        rowKey: 'id',
                        columns: columns,
                        remote: false,
                        actionButtons: actionButtons,
                        rowSelection: datatableRowSelection,
                        subtitle: T('price.will.be.changed'),
                        locale: { emptyText: T('result.not.found') },
                        paginationText: T('show.variable').format(''),
                        dataSource: tradableTableItems,
                        url: `products/${initialProductCode}/excel_export`,
                        isQueryOptions: false,
                      }}
                    />
                  </LayoutContentWrapper>
                </Col>
              </Row>

              <Row>
                <Col span={12}>
                  <Modal
                    title={T('changeable.product.rules.price.change')}
                    visible={visibleModal}
                    footer={[
                      <Button key="back" onClick={() => setVisibleModal(false)}>
                        {T('cancel')}
                      </Button>,
                      <Button
                        type="primary"
                        onClick={() => onSubmitChangePrice()}
                      >
                        {T('confirm')}
                      </Button>,
                    ]}
                  >
                    <p>
                      {selectedDataIds.length === 0
                        ? T('changele.product.rules.price.change.modal.all')
                        : T('changeable.product.rules.price.change.modal')}
                    </p>
                  </Modal>
                </Col>
              </Row>
            </Tabs.TabPane>
          )}
        </Tabs>
      </Box>
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    tradeRuleSet: state.tradeInReducer.tradeRuleSet,
    products: state.tradeInReducer.products,
    tradableItems: state.tradeInReducer.tradableItems,
    brands: state.tradeInReducer.brands,
  };
};
const mapDispatchToProps = {
  getTradeRuleSet,
  getProducts,
  getTradableItemList,
  getProduct,
  getBrands,
  updateBulkRuleProduct,
  getBulkProductRuleStatus,
};

const ChangeableProductRulesDetailForm = Form.create({
  name: 'changable-product-rules-detail',
})(ChangeableProductRulesDetail);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeableProductRulesDetailForm);
