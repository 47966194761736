import { inputTypes } from '@components/DynamicForm/constants';
import {
  validationTypes,
  activePassiveBooleanOptions,
  changeableProductCategoryOptions,
} from '@constants/commontypes';
import { inputRenderer } from '@utils/index';
import { T } from '@utils/languageProvider';

import { emptyProductGroup, QuestionSetFormInputKey } from './common';

export default function getFormInputs({
  questionSet,
  productGroups,
  isProductGroupSelected,
  id,
}) {
  const filteredProductGroup = {};
  const findIsProductGroupIsInQuestionSet = (productGroup) =>
    !productGroup.in_use_by_questionset ||
    productGroup.id === questionSet?.group?.id;
  filteredProductGroup.results = productGroups?.results?.filter(
    findIsProductGroupIsInQuestionSet
  );

  if (filteredProductGroup?.results) {
    filteredProductGroup.results.push(emptyProductGroup);
  }

  const formInputs = [
    {
      pk: 1,
      name: T('name'),
      key: QuestionSetFormInputKey.NAME,
      data_type: { value: inputTypes.input.alias },
      fieldOptions: {
        rules: [validationTypes.required(T('name'))],
      },
      fieldProps: {
        placeholder: T('enter.variable').format(T('name')),
      },
    },
    {
      pk: 2,
      name: T('slug'),
      key: QuestionSetFormInputKey.SLUG,
      data_type: { value: inputTypes.input.alias },
      fieldOptions: {
        rules: [validationTypes.required(T('slug'))],
      },
      fieldProps: {
        placeholder: T('enter.variable').format(T('slug')),
      },
    },
    {
      pk: 3,
      name: T('min.price.1'),
      key: QuestionSetFormInputKey.MIN_PRICE,
      data_type: { value: inputTypes.input.alias },
      fieldOptions: {
        rules: [
          validationTypes.required(T('min.price.1')),
          validationTypes.number,
        ],
      },
      fieldProps: {
        placeholder: T('enter.variable').format(T('min.price.1')),
      },
    },
    {
      pk: 4,
      name: T('max.price.1'),
      key: QuestionSetFormInputKey.MAX_PRICE,
      data_type: { value: inputTypes.input.alias },
      fieldOptions: {
        rules: [
          validationTypes.required(T('max.price.1')),
          validationTypes.number,
        ],
      },
      fieldProps: {
        placeholder: T('enter.variable').format(T('max.price.1')),
      },
    },
    {
      pk: 5,
      name: T('status'),
      key: QuestionSetFormInputKey.STATUS,
      data_type: { value: inputTypes.select.alias },
      visible_values: activePassiveBooleanOptions,
      default_value: questionSet?.group?.is_active,
      fieldProps: {
        placeholder: T('status'),
      },
    },
    {
      pk: 6,
      name: T('product.groups'),
      key: QuestionSetFormInputKey.PRODUCT_GROUP,
      data_type: { value: inputTypes.remoteselect.alias },
      dataSource: filteredProductGroup,
      objectKey: 'results',
      labelKeyName: 'name',
      valueKeyName: 'id',
      placeholder: T('enter.variable').format(T('product.groups')),
      fieldProps: {
        defaultValue: id ? questionSet?.group?.id?.toString() || '0' : '0',
      },
    },
    {
      pk: 7,
      name: T('changeable.product.category'),
      key: QuestionSetFormInputKey.PRODUCT_CATEGORY,
      data_type: { value: inputTypes.select.alias },
      visible_values: changeableProductCategoryOptions,
      fieldProps: {
        disabled: isProductGroupSelected,
      },
    },
  ];

  return inputRenderer(formInputs, id, questionSet);
}
