import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Block from '@components/Block';
import Icon from '@components/Intrinsic';
import Box from '@components/utility/box/index';
import Select, { SelectOption as Option } from '@components/UIElements/Select';

import { navigator } from '@common/navigation';
import { inputTypes } from '@components/DynamicForm/constants';
import { formLayoutTypes, validationTypes } from '@constants/commontypes';
import Form from '@components/UIElements/form';
import { T } from '@utils/languageProvider';
import { removeElementsByKey } from '@utils';
import {
  getProductGroup,
  saveProductGroup,
  saveAdditionalDiscountLimits,
  deleteProductGroup,
  addProductsToProductGroup,
  getSimplifiedProducts,
  deleteProductGroupAdditionalDiscount,
  removeProductFromGroup,
  resetProductGroup,
} from '@resources/actions/tradeIn';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { logResponseMessages } from '@common/';
import { isEmpty } from 'lodash';

import './style.scss';
import { Button, Steps } from 'antd';

const { Step } = Steps;
const FormItem = Form.Item;

/* eslint-disable no-unused-expressions */

const ProductGroupDetail = (props) => {
  const {
    getProductGroup,
    productGroup,
    saveProductGroup,
    simplifiedProducts,
    deleteProductGroup,
    deleteProductGroupAdditionalDiscount,
    addProductsToProductGroup,
    getSimplifiedProducts,
    saveAdditionalDiscountLimits,
    current = 0,
  } = props;
  const params = useParams();
  const { id } = params;
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentStep, setCurrentStep] = useState(current);

  const groupFormValuesRef = useRef({});
  const categoryFormValuesRef = useRef({});
  const productsRef = useRef([]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      id && (await getProductGroup(id));
      await getSimplifiedProducts();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [getProductGroup, getSimplifiedProducts, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      resetProductGroup();
    };
  }, []);

  const onNavigate = (url) => {
    navigator.push(url);
  };

  const onSubmit = async (values) => {
    const {
      // products,
      wearable_additional_discount_limit,
      tablet_additional_discount_limit,
      phone_additional_discount_limit,
      tv_additional_discount_limit,
      refrigerator_additional_discount_limit,
      smart_home_additional_discount_limit,
      dishwasher_additional_discount_limit,
      washing_machine_additional_discount_limit,
      oven_additional_discount_limit,
      frame_additional_discount_limit,
      power_additional_discount_limit,
      memory_and_storage_additional_discount_limit,
      climate_additional_discount_limit,
      air_conditioner_additional_discount_limit,
      earbuds_additional_discount_limit,
      tumble_dryer_additional_discount_limit,
      laser_projector_additional_discount_limit,
      monitor_additional_discount_limit,
      cooking_appliances_additional_discount_limit,
      sound_system_additional_discount_limit,
      vacuum_cleaner_additional_discount_limit,
      simple_additional_discount_limit,
      additional_discount_limit,
      scrap_additional_discount_limit,
      ...restOfValues
    } = values;
    let result;
    const additionalDiscountLimits = {
      wearable_additional_discount_limit,
      tablet_additional_discount_limit,
      phone_additional_discount_limit,
      additional_discount_limit,
      tv_additional_discount_limit,
      refrigerator_additional_discount_limit,
      smart_home_additional_discount_limit,
      dishwasher_additional_discount_limit,
      washing_machine_additional_discount_limit,
      oven_additional_discount_limit,
      frame_additional_discount_limit,
      power_additional_discount_limit,
      memory_and_storage_additional_discount_limit,
      climate_additional_discount_limit,
      air_conditioner_additional_discount_limit,
      earbuds_additional_discount_limit,
      tumble_dryer_additional_discount_limit,
      laser_projector_additional_discount_limit,
      monitor_additional_discount_limit,
      cooking_appliances_additional_discount_limit,
      sound_system_additional_discount_limit,
      vacuum_cleaner_additional_discount_limit,
      simple_additional_discount_limit,
      scrap_additional_discount_limit,
    };
    setSubmitting(true);
    try {
      result = await saveProductGroup(id, restOfValues);
      if (id) {
        await saveAdditionalDiscountLimits(id, additionalDiscountLimits);
        const products = productsRef.current?.map((product) => product.id);
        if (products?.length > 0) {
          await addProductsToProductGroup(id, { products });
        }
      }
    } catch (err) {
      setSubmitting(false);
      throw err;
    }
    setSubmitting(false);
    if (!id) {
      onNavigate(`/trade-in/manage-products/product-groups/form/${result.id}`);
    } else {
      onNavigate(`/trade-in/manage-products/product-groups`);
    }
  };

  const onSubmitProductGroups = async (values) => {
    setCurrentStep(1);
  };

  const onDelete = async () => {
    setDeleting(true);
    if (productGroup.in_use_by_questionset || productGroup.in_use_by_product) {
      logResponseMessages({
        type: 'error',
        message:
          'Bu ürün grubu başka ürünler tarafından kullanıldığı için silinemez',
      });
      setDeleting(false);
      return;
    }
    try {
      await deleteProductGroup(id);
    } catch (err) {
    } finally {
      setDeleting(false);
      onNavigate(`/trade-in/product-groups`);
    }
  };

  const onDeleteAdditionalDiscount = async () => {
    setDeleting(true);
    try {
      await deleteProductGroupAdditionalDiscount(id);
    } catch (err) {
      console.log(err);
    } finally {
      setDeleting(false);
      onNavigate(`/trade-in/manage-products/product-groups`);
    }
  };

  const ProductListItem = ({ label, value, icon, onClick }) => {
    const handleClick = (e) => (value, label) => {
      e.preventDefault();
      onClick?.(value, label);
    };
    return (
      <Block
        className="product-list-item ant-select-dropdown-menu-item"
        onClick={(e) => handleClick(e)(value, label)}
      >
        <span className="product-list-item-label">{label}</span>
        {icon && <span>{icon}</span>}
      </Block>
    );
  };

  const ProductSelect = ({ products, onItemClick, onAddAll }) => {
    const [searchStr, setSearchStr] = useState('');
    const selectDivRef = useRef(null);
    const handleAddAll = (e) => {
      onAddAll?.(
        products.filter((item) => item.remote_identifier?.startsWith(searchStr))
      );
      selectDivRef?.current?.focus();
    };
    return (
      <>
        <Block>
          <Select
            className="product-select"
            showSearch
            value={null} // seçim yapıldığında üst text box içinde bir şey gözükmesin diye...
            filterOption={(input, option) => {
              return option.props?.label
                .toLowerCase()
                .startsWith(input.toLowerCase());
            }}
            onSearch={(value) => {
              setSearchStr(value);
            }}
            dropdownRender={(menu) => (
              // İlgili div fokuslanabilir olsun diye tabindex ekledik.
              <div tabindex="0" ref={selectDivRef}>
                {menu}
                <Block
                  style={{ backgroundColor: '#f1f3f9', padding: '15px 0' }}
                  onMouseDown={(e) => e.preventDefault()} // Bunu eklemezsen diğer olaylar çalışmıyor.
                >
                  {searchStr && (
                    <FormItem>
                      <Button
                        type="primary"
                        htmlType="button"
                        className="add-all-button styled-buttons deneme"
                        onClick={handleAddAll}
                      >
                        {T('add.all.matching.products').format(searchStr)}
                      </Button>
                    </FormItem>
                  )}
                </Block>
              </div>
            )}
          >
            {products?.map((product, idx) => (
              <Option
                key={'pli_' + idx}
                value={product?.id}
                label={product?.remote_identifier}
              >
                <ProductListItem
                  label={product?.remote_identifier}
                  value={product?.id}
                  icon={
                    <i className="icon-plus" style={{ color: '#7ed321' }} />
                  }
                  onClick={onItemClick}
                />
              </Option>
            ))}
          </Select>
        </Block>
      </>
    );
  };

  const ProductRow = ({ label, value, onClick }) => {
    const handleClick = (e) => (value, label) => {
      e.preventDefault();
      onClick?.(value, label);
    };
    return (
      <Block className="product-row">
        <span className="product-row-label">{label}</span>
        <span
          className="product-row-icon"
          onClick={(e) => handleClick(e)(value, label)}
        >
          <i className="icon-minus" style={{ color: '#ff5163' }} />
        </span>
      </Block>
    );
  };

  const ProductBox = ({
    initialProducts = [],
    initialList = [],
    onAddProduct,
    onRemoveProduct,
  }) => {
    const sortFunction = (a, b) =>
      a.remote_identifier.localeCompare(b.remote_identifier);

    const [list, setList] = useState(initialList);

    // Select öğeleri içinde aşağıdaki listeden öğeler varsa onları çıkart ve alfabetik sırala.
    const [productsList, setProductsList] = useState(
      removeElementsByKey(initialProducts, initialList, 'id').sort(sortFunction)
    );

    const handleAddProduct = (value, label) => {
      const alreadyInTheList = list.find((item) => item.id === value);
      if (!alreadyInTheList) {
        const newList = [...list, { id: value, remote_identifier: label }];
        setList(newList);
        setProductsList(productsList.filter((item) => item.id !== value));
        onAddProduct?.(newList);
      }
    };

    const handleAddAll = (itemList) => {
      productsRef.current = [...productsRef.current, ...itemList];
      setProductsList(
        removeElementsByKey(productsList, itemList, 'id').sort(sortFunction)
      );
      setList([...list, ...itemList]);
    };

    const handleRemoveProduct = (value, label) => {
      const newList = list.filter((item) => item.id !== value);
      setList(newList);
      // Aşağıdaki listeden çıkartılan öğeyi tekrar select içine ekle ve select öğelerini alfabetik sırala.
      setProductsList(
        [...productsList, { id: value, remote_identifier: label }].sort(
          sortFunction
        )
      );
      onRemoveProduct?.({ id: value, remote_identifier: label }, newList);
    };

    return (
      <Block className="product-box">
        <Block className="box-label">
          <label>{T('products')}</label>
        </Block>
        <ProductSelect
          products={productsList}
          onItemClick={handleAddProduct}
          onAddAll={handleAddAll}
        />
        <Block className="product-list">
          {list?.map((product, idx) => (
            <ProductRow
              key={'pli_' + idx}
              label={product?.remote_identifier}
              value={product?.id}
              onClick={(value, label) => handleRemoveProduct(value, label)}
            />
          ))}
        </Block>
      </Block>
    );
  };

  const filteredProducts = simplifiedProducts?.filter(
    (product) => !product.group_id
  );

  const getProductGroupItems = () => {
    const groupFormValues = groupFormValuesRef.current;
    return [
      {
        name: T('product.group.name'),
        key: 'name',
        data_type: { value: inputTypes.input.alias },
        default_value: groupFormValues?.name || productGroup?.name,
        fieldProps: {
          placeholder: T('enter.variable').format(T('product.group.name')),
        },
        fieldOptions: {
          rules: [validationTypes.required(T('name'))],
          initialValue: groupFormValues?.name || productGroup?.name,
        },
      },
      id && {
        name: T('additional.support.amount'),
        key: 'additional_discount_limit',
        objectKey: 'additional_discount',
        data_type: { value: inputTypes.input.alias },
        type: 'number',
        default_value:
          groupFormValues?.additional_discount_limit ||
          productGroup?.additional_discount?.additional_discount_limit,
        fieldOptions: {
          rules: [
            validationTypes.required(T('additional.support.amount')),
            validationTypes.number,
          ],
          initialValue:
            groupFormValues?.additional_discount_limit ||
            productGroup?.additional_discount?.additional_discount_limit,
        },
        fieldProps: {
          placeholder: T('enter.variable').format(
            T('additional.support.amount')
          ),
        },
      },
      {
        name: T('tradable.item.limit'),
        key: 'tradable_item_limit',
        data_type: { value: inputTypes.input.alias },
        default_value:
          groupFormValues?.tradable_item_limit ||
          productGroup?.tradable_item_limit ||
          '0',
        type: 'number',
        fieldOptions: {
          rules: [
            validationTypes.required(T('tradable.item.limit')),
            validationTypes.number,
          ],
          initialValue:
            groupFormValues?.tradable_item_limit ||
            productGroup?.tradable_item_limit ||
            '0',
        },
        fieldProps: {
          placeholder: T('enter.variable').format(T('tradable.item.limit')),
        },
      },
      id && {
        name: T('additional.simple.amount'),
        key: 'simple_additional_discount_limit',
        objectKey: 'additional_discount',
        data_type: { value: inputTypes.input.alias },
        type: 'number',
        default_value:
          groupFormValues?.simple_additional_discount_limit ||
          productGroup?.additional_discount?.simple_additional_discount_limit ||
          '0',
        fieldOptions: {
          rules: [
            validationTypes.required(T('additional.simple.amount')),
            validationTypes.number,
          ],
          initialValue:
            groupFormValues?.simple_additional_discount_limit ||
            productGroup?.additional_discount
              ?.simple_additional_discount_limit ||
            '0',
        },
        fieldProps: {
          placeholder: T('enter.variable').format(
            T('additional.simple.amount')
          ),
        },
      },
    ]
      .map((item, idx) => (!isEmpty(item) ? { pk: idx + 1, ...item } : null))
      .filter(Boolean);
  };

  const getCategoryFieldsList = () => {
    const categoryFormValues = categoryFormValuesRef.current;
    return [
      {
        label: T('phone'),
        limitField: {
          name: T('phone.item.limit'),
          key: 'phone_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('phone.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('tradable.item.count'),
          },
        },
        amountField: {
          name: T('phone.additional.support.amount'),
          key: 'phone_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          default_value:
            productGroup?.additional_discount
              ?.phone_additional_discount_limit || '0',
          fieldOptions: {
            rules: [
              validationTypes.required(T('phone.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('phone.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('tablet'),
        limitField: {
          name: T('tablet.item.limit'),
          key: 'tablet_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('tablet.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('tablet.item.limit')),
          },
        },
        amountField: {
          name: T('tablet.additional.support.amount'),
          key: 'tablet_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          default_value:
            productGroup?.additional_discount
              ?.tablet_additional_discount_limit || '0',
          fieldOptions: {
            rules: [
              validationTypes.required(T('tablet.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('tablet.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('smart_watch'),
        limitField: {
          name: T('wearable.item.limit'),
          key: 'wearable_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('wearable.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('wearable.item.limit')),
          },
        },
        amountField: {
          name: T('wearable.additional.support.amount'),
          key: 'wearable_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.wearable_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('wearable.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('wearable.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('tv'),
        limitField: {
          name: T('tv.item.limit'),
          key: 'tv_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('tv.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('tv.item.limit')),
          },
        },
        amountField: {
          name: T('tv.additional.support.amount'),
          key: 'tv_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount?.tv_additional_discount_limit ||
            '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('tv.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('tv.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('refrigerator'),
        limitField: {
          name: T('refrigerator.item.limit'),
          key: 'refrigerator_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('refrigerator.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('refrigerator.item.limit')
            ),
          },
        },
        amountField: {
          name: T('refrigerator.additional.support.amount'),
          key: 'refrigerator_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.refrigerator_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('refrigerator.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('refrigerator.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('smart_home'),
        limitField: {
          name: T('smart_home.item.limit'),
          key: 'smart_home_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('smart_home.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('smart_home.item.limit')),
          },
        },
        amountField: {
          name: T('smart_home.additional.support.amount'),
          key: 'smart_home_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.smart_home_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('smart_home.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('smart_home.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('dishwasher'),
        limitField: {
          name: T('dishwasher.item.limit'),
          key: 'dishwasher_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('dishwasher.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('dishwasher.item.limit')),
          },
        },
        amountField: {
          pk: 20,
          name: T('dishwasher.additional.support.amount'),
          key: 'dishwasher_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.dishwasher_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('dishwasher.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('dishwasher.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('washing_machine'),
        limitField: {
          name: T('washing_machine.item.limit'),
          key: 'washing_machine_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('washing_machine.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('washing_machine.item.limit')
            ),
          },
        },
        amountField: {
          name: T('washing_machine.additional.support.amount'),
          key: 'washing_machine_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.washing_machine_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('washing_machine.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('washing_machine.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('oven'),
        limitField: {
          name: T('oven.item.limit'),
          key: 'oven_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('oven.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('oven.item.limit')),
          },
        },
        amountField: {
          name: T('oven.additional.support.amount'),
          key: 'oven_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount?.oven_additional_discount_limit ||
            '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('oven.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('oven.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('frame'),
        limitField: {
          name: T('frame.item.limit'),
          key: 'frame_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('frame.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('frame.item.limit')),
          },
        },
        amountField: {
          name: T('frame.additional.support.amount'),
          key: 'frame_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.frame_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('frame.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('frame.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('power'),
        limitField: {
          name: T('power.item.limit'),
          key: 'power_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('power.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('power.item.limit')),
          },
        },
        amountField: {
          name: T('power.additional.support.amount'),
          key: 'power_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.power_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('power.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('power.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('memory_and_storage'),
        limitField: {
          name: T('memory_and_storage.item.limit'),
          key: 'memory_and_storage_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('memory_and_storage.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('memory_and_storage.item.limit')
            ),
          },
        },
        amountField: {
          name: T('memory_and_storage.additional.support.amount'),
          key: 'memory_and_storage_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.memory_and_storage_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('memory_and_storage.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('memory_and_storage.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('climate'),
        limitField: {
          name: T('climate.item.limit'),
          key: 'climate_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('climate.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('climate.item.limit')),
          },
        },
        amountField: {
          name: T('climate.additional.support.amount'),
          key: 'climate_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.climate_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('climate.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('climate.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('air_conditioner'),
        limitField: {
          name: T('air.conditioner.item.limit'),
          key: 'air_conditioner_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('air.conditioner.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('air.conditioner.item.limit')
            ),
          },
        },
        amountField: {
          name: T('air.conditioner.additional.support.amount'),
          key: 'air_conditioner_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.air_conditioner_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('air.conditioner.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('air.conditioner.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('earbuds'),
        limitField: {
          name: T('earbuds.item.limit'),
          key: 'earbuds_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('earbuds.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('earbuds.item.limit')),
          },
        },
        amountField: {
          name: T('earbuds.additional.support.amount'),
          key: 'earbuds_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.earbuds_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('earbuds.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('earbuds.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('tumble_dryer'),
        limitField: {
          name: T('tumble.dryer.item.limit'),
          key: 'tumble_dryer_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('tumble.dryer.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('tumble.dryer.item.limit')
            ),
          },
        },
        amountField: {
          name: T('tumble.dryer.additional.support.amount'),
          key: 'tumble_dryer_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.tumble_dryer_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('tumble.dryer.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('tumble.dryer.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('laser_projector'),
        limitField: {
          name: T('laser.projector.item.limit'),
          key: 'laser_projector_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('laser.projector.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('laser.projector.item.limit')
            ),
          },
        },
        amountField: {
          name: T('laser.projector.additional.support.amount'),
          key: 'laser_projector_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.laser_projector_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('laser.projector.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('laser.projector.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('monitor'),
        limitField: {
          name: T('monitor.item.limit'),
          key: 'monitor_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('monitor.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(T('monitor.item.limit')),
          },
        },
        amountField: {
          name: T('monitor.additional.support.amount'),
          key: 'monitor_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.monitor_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('monitor.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('monitor.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('cooking_appliances'),
        limitField: {
          name: T('cooking.appliances.item.limit'),
          key: 'cooking_appliances_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('cooking.appliances.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('cooking.appliances.item.limit')
            ),
          },
        },
        amountField: {
          name: T('cooking.appliances.additional.support.amount'),
          key: 'cooking_appliances_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.cooking_appliances_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('cooking.appliances.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('cooking.appliances.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('sound_system'),
        limitField: {
          name: T('sound.system.item.limit'),
          key: 'sound_system_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('sound.system.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('sound.system.item.limit')
            ),
          },
        },
        amountField: {
          name: T('sound.system.additional.support.amount'),
          key: 'sound_system_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.sound_system_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('sound.system.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('sound.system.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('vacuum_cleaner'),
        limitField: {
          name: T('vacuum.cleaner.item.limit'),
          key: 'vacuum_cleaner_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('vacuum.cleaner.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('vacuum.cleaner.item.limit')
            ),
          },
        },
        amountField: {
          name: T('vacuum.cleaner.additional.support.amount'),
          key: 'vacuum_cleaner_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          default_value:
            productGroup?.additional_discount
              ?.vacuum_cleaner_additional_discount_limit || '0',
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(
                T('vacuum.cleaner.additional.support.amount')
              ),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('vacuum.cleaner.additional.support.amount')
            ),
          },
        },
      },
      {
        label: T('scrap'),
        limitField: {
          name: T('scrap.item.limit'),
          key: 'scrap_item_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          fieldOptions: {
            rules: [
              validationTypes.required(T('scrap.item.limit')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('tradable.item.count'),
          },
        },
        amountField: {
          name: T('scrap.additional.support.amount'),
          key: 'scrap_additional_discount_limit',
          data_type: { value: inputTypes.input.alias },
          type: 'number',
          default_value:
            productGroup?.additional_discount
              ?.scrap_additional_discount_limit || '0',
          fieldOptions: {
            rules: [
              validationTypes.required(T('scrap.additional.support.amount')),
              validationTypes.number,
            ],
          },
          fieldProps: {
            placeholder: T('enter.variable').format(
              T('scrap.additional.support.amount')
            ),
          },
        },
      },
    ].map((item, idx) => ({
      ...item,
      limitField: {
        ...item.limitField,
        fieldProps: {
          ...item.limitField.fieldProps,
          placeholder: T('tradable.item.count'),
        },
        fieldOptions: {
          ...item.limitField.fieldOptions,
          rules: [
            validationTypes.required(T('tradable.item.count')),
            validationTypes.number,
          ],
          initialValue:
            categoryFormValues?.[item.limitField.key] ||
            productGroup?.[item.limitField.key] ||
            0,
        },
      },
      amountField: {
        ...item.amountField,
        fieldProps: {
          ...item.amountField.fieldProps,
          placeholder: T('support.amount'),
        },
        fieldOptions: {
          ...item.amountField.fieldOptions,
          rules: [
            validationTypes.required(T('support.amount')),
            validationTypes.number,
          ],
          initialValue:
            categoryFormValues?.[item.amountField.key] ||
            productGroup?.additional_discount?.[item.amountField.key] ||
            0,
        },
      },
      pk: idx + 1,
    }));
  };

  const productGroupInputs = productGroup && getProductGroupItems();

  const filteredSimplifiedProducts =
    simplifiedProducts.length > 0 &&
    simplifiedProducts.filter((product) => product.group_id == id);

  const CategoryItem = ({
    label,
    limitField,
    amountField,
    fieldDecorator,
    groupId,
  }) => {
    return (
      <Block className="category-item ant-row form-wrapper">
        <label className="category-item-label">{label}</label>

        <FormItem className="limit-field">
          {fieldDecorator(limitField?.key, { ...limitField?.fieldOptions })(
            <input
              className="ant-input inputWrapper"
              {...limitField?.fieldProps}
            />
          )}
        </FormItem>
        {groupId && (
          <FormItem className="amount-field">
            {fieldDecorator(amountField?.key, { ...amountField?.fieldOptions })(
              <input
                className="ant-input inputWrapper"
                {...amountField?.fieldProps}
              />
            )}
          </FormItem>
        )}
      </Block>
    );
  };

  const CategoryForm = ({ categoryFieldsList, form, groupId, onSubmit }) => {
    const { getFieldDecorator, getFieldsValue } = form;

    useEffect(() => {
      categoryFormValuesRef.current = getFieldsValue();
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          categoryFormValuesRef.current = values;
          const groupFormValues = groupFormValuesRef.current || {};
          onSubmit?.({ ...values, ...groupFormValues });
        }
      });
    };

    return (
      <Box
        title={T('categories')}
        subtitle={T('detail.desc').format(T('category'))}
        className="box-primary form-box"
      >
        <Form className="category-list-form" onSubmit={handleSubmit}>
          {categoryFieldsList.map((category) => (
            <CategoryItem
              key={category.pk}
              {...category}
              fieldDecorator={getFieldDecorator}
              groupId={groupId}
            />
          ))}

          <Block className="submit-button-row">
            <FormItem>
              <Button type="primary" htmlType="submit" loading={submitting}>
                {T('save')}
              </Button>
            </FormItem>
            {groupId && (
              <FormItem>
                <Button
                  type="danger"
                  htmlType="button"
                  loading={deleting}
                  onClick={onDelete}
                >
                  {T('delete')}
                </Button>
              </FormItem>
            )}

            {groupId && (
              <FormItem>
                <Button
                  type="danger"
                  htmlType="button"
                  loading={deleting}
                  onClick={onDeleteAdditionalDiscount}
                >
                  {T('clear.additional.support')}
                </Button>
              </FormItem>
            )}
          </Block>
        </Form>
      </Box>
    );
  };

  const GroupForm = ({ groupFieldsList, form, onSubmit }) => {
    const { getFieldDecorator: fieldDecorator, getFieldsValue } = form;

    useEffect(() => {
      groupFormValuesRef.current = getFieldsValue();
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      form.validateFields((err, values) => {
        if (!err) {
          groupFormValuesRef.current = values;
          onSubmit?.(values);
        }
      });
    };

    const saveProducts = (products) => {
      productsRef.current = products;
    };

    const handleRemoveProduct = async (product, newList) => {
      saveProducts(newList);
      if (filteredSimplifiedProducts.find((p) => p.id == product.id)) {
        try {
          setLoading(true);
          await removeProductFromGroup(product.remote_identifier);
          await fetchData();
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };

    return (
      <Box
        title={T('add.edit').format(T('product.groups'))}
        subtitle={T('detail.desc').format(T('product.groups'))}
        className="box-primary form-box"
      >
        <Form className="group-form" onSubmit={handleSubmit}>
          {groupFieldsList.map((field, idx) => (
            <FormItem key={'grp_fld_' + idx} label={field.name}>
              {fieldDecorator(field?.key, { ...field?.fieldOptions })(
                <input
                  className="ant-input inputWrapper"
                  {...field?.fieldProps}
                />
              )}
            </FormItem>
          ))}
          {id && simplifiedProducts.length > 0 && (
            <ProductBox
              initialProducts={filteredProducts}
              initialList={
                productsRef.current?.length > 0
                  ? productsRef.current
                  : filteredSimplifiedProducts
              }
              onAddProduct={saveProducts}
              onRemoveProduct={handleRemoveProduct}
            ></ProductBox>
          )}

          <Block className="submit-button-row">
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                className="save-button styled-buttons"
              >
                {T('save.and.continue')}
              </Button>
            </FormItem>
          </Block>
        </Form>
      </Box>
    );
  };

  const WrappedCategoryForm = Form.create({ name: 'category_form' })(
    CategoryForm
  );

  const WrappedGroupForm = Form.create({ name: 'group_form' })(GroupForm);

  const handleStepChange = (curr) => {
    // initial valuları öncelikle bu değerler olmalı
    setCurrentStep(curr);
  };

  return (
    <LayoutContentWrapper
      className="changeable-products-form"
      loading={loading}
    >
      <Block className="group-product-steps-wrapper">
        <Block className="group-product-steps">
          <Steps
            labelPlacement="vertical"
            current={parseInt(currentStep)}
            onChange={handleStepChange}
          >
            <Step
              title={T('product.groups')}
              icon={<Icon className="icon-urunlistesi" />}
            />
            <Step
              title={T('categories')}
              icon={<Icon className="icon-fiyatlistesi" />}
            />
          </Steps>
        </Block>
      </Block>

      {currentStep === 0 && (
        <>
          <WrappedGroupForm
            groupFieldsList={productGroupInputs}
            onSubmit={onSubmitProductGroups}
          />
        </>
      )}
      {currentStep === 1 && (
        <WrappedCategoryForm
          // categoryFieldsList={categoryFieldsList.slice(0, 2)}
          categoryFieldsList={getCategoryFieldsList()}
          groupId={id}
          onSubmit={onSubmit}
        />
      )}
    </LayoutContentWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    productGroup: state.tradeInReducer.productGroup,
    simplifiedProducts: state.tradeInReducer.simplifiedProducts,
  };
};

const mapDispatchToProps = {
  getProductGroup,
  saveProductGroup,
  deleteProductGroup,
  deleteProductGroupAdditionalDiscount,
  addProductsToProductGroup,
  getSimplifiedProducts,
  saveAdditionalDiscountLimits,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGroupDetail);
