import React from 'react';

import Block from '@components/Block';
import Button from '@components/Button';
import { T } from '@utils/languageProvider';
import { useParams } from 'react-router';

import { onNavigate } from './common';

const DatatableCustomHeader = () => {
  const { id } = useParams();

  return (
    <Block className="question-set-datatable-header">
      <Button
        type="secondary"
        icon="icon-arti"
        onClick={() => onNavigate(`/trade-in/question-set/form/question/${id}`)}
      >
        {T('new.add').format(T('question'))}
      </Button>
    </Block>
  );
};

export default DatatableCustomHeader;
