// export const baseURL = "https://lcw-bo.akinon.net/api";

export const defaultDateFormat = 'DD/MM/YYYY';
export const defaultDateValueFormat = 'YYYY-MM-DD';
export const defaultTimeFormat = 'HH:mm';
export const defaultTimeFormatWithSeconds = 'HH:mm:ss';
export const defaultTimeFormatWithTimeZone = 'HH:mm:ssZZ';
export const timeFormatWithTimeZone = 'HH:mmZ';
export const timeFormatWithDateAndTime = 'LLL';
export const dateWithMonthName = 'DD MMMM';
export const overallDateFormat = 'DD-MM-YYYY HH:mm:ss';

export const defaultDateTimeValueFormat = `${defaultDateValueFormat} ${defaultTimeFormatWithSeconds}`;
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;

export const defaultDateTimeFormatWithTimeZone = `${defaultDateValueFormat} ${timeFormatWithTimeZone}`;
export const dateTimeFormat = `${defaultDateValueFormat} ${defaultTimeFormat}`;
export const dateFormatWithTimeZone = `${defaultDateValueFormat}[T]${defaultTimeFormatWithSeconds}.SSSSSS[Z]`;

export const tokenHeaderKEY = 'Token';
export const tokenStorageKey = 'tkn';

export const googleMapApiKey = 'AIzaSyB7GLs4svXGax91fiW-gH1wInjmde7cEHk';

export const apiLanguageKey = 'LANGUAGE';

export const permissionSlugSuffix = '-v2';

export const readResponseMessageKey = 'readResponseMessages';

export const platformLanguage = 'platform_language';

export const contextChannel = 'contextChannel';

export const allowedFileTypes = '.xls, .csv, .xlsx';

