import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select, { SelectOption } from '../UIElements/Select';
import { sendRequest } from '@common/network';
import { isDefinedAndNotEmpty, getObjectFromString } from '@utils';
import { filterEnum } from '@constants/commontypes';

class RemoteSelect extends PureComponent {
  state = {
    options: [],
  };

  componentDidMount() {
    const { url, related } = this.props;

    if (isDefinedAndNotEmpty(url)) {
      this.getOptions(url, related, true);
    } else {
      this.getOptionsWithOutUrl();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (isDefinedAndNotEmpty(nextProps.url) &&
        this.props.url !== nextProps.url) ||
      this.props.related !== nextProps.related
    )
      this.getOptions(nextProps.url, nextProps.related);
  }

  getOptionsWithOutUrl = () => {
    const { objectKey, dataSource } = this.props;
    this.setState({
      options: objectKey
        ? getObjectFromString(objectKey, dataSource)
        : dataSource,
    });
  };

  getOptions = (url, related, initial) => {
    const { objectKey, relatedKey, onChange, defaultOptions } = this.props;
    const relatedValue = isDefinedAndNotEmpty(related)
      ? typeof related === 'object'
        ? related.key
        : related
      : null;
    if (relatedKey && !isDefinedAndNotEmpty(relatedValue)) {
      this.setState(
        {
          options: [],
        },
        () => {
          if (!initial) onChange();
        }
      );
    } else {
      if (!initial) onChange();
      sendRequest({
        params: relatedValue ? { [relatedKey]: relatedValue } : {},
        url,
        onSuccess: (result) => {
          const options = objectKey
            ? getObjectFromString(objectKey, result)
            : result;
          const optionsWithDefaultOptions = defaultOptions
            ? [...options, defaultOptions]
            : [...options];
          this.setState(
            {
              options: optionsWithDefaultOptions,
            },
            () => {
              this.props.onRequestEnd &&
                this.props.onRequestEnd(this.state.options);
            }
          );
        },
      });
    }
  };

  filterOption = (inputValue, option) => {
    const label = option.props.label;
    const { filterOption, filterType } = this.props;
    if (filterOption) return filterOption(inputValue, label);
    if (!isDefinedAndNotEmpty(inputValue) || !isDefinedAndNotEmpty(label))
      return true;
    if (filterType) {
      if (filterType === filterEnum.contains)
        return label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
      else return label.toLowerCase().startsWith(inputValue.toLowerCase());
    }
    return label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
  };

  render() {
    const {
      url,
      keyName,
      valueKeyName,
      labelKeyName,
      relatedKey,
      related,
      disabled,
      defaultKey,
      defaultValue,
      ...otherProps
    } = this.props;
    const { options } = this.state;
    const disabledProp =
      disabled === true ? true : relatedKey ? !related : false;
    const optionSelectors =
      options &&
      options
        .filter(
          (item) =>
            isDefinedAndNotEmpty(getObjectFromString(labelKeyName, item)) &&
            isDefinedAndNotEmpty(getObjectFromString(valueKeyName, item))
        )
        .map((item, index) => {
          const label =
            getObjectFromString(labelKeyName, item).toString() ||
            getObjectFromString(valueKeyName, item).toString();
          return (
            <SelectOption
              key={keyName ? getObjectFromString(keyName, item) : index}
              value={getObjectFromString(valueKeyName, item).toString()}
              label={label}
            >
              {label}
            </SelectOption>
          );
        });
    return (
      <Select
        {...otherProps}
        key={defaultValue || defaultKey}
        disabled={disabledProp}
        defaultValue={defaultValue}
        filterOption={this.filterOption}
      >
        {optionSelectors}
      </Select>
    );
  }
}

RemoteSelect.defaultProps = {
  valueKeyName: 'value',
  labelKeyName: 'label',
  defaultKey: 'remoteSelect',
  objectKey: 'visible_values',
};

RemoteSelect.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  dataSource: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  related: PropTypes.any,
  relatedKey: PropTypes.string,
  objectKey: PropTypes.string,
  valueKeyName: PropTypes.string,
  labelKeyName: PropTypes.string,
  keyName: PropTypes.string,
  disabled: PropTypes.bool,
  filterOption: PropTypes.func,
  filterType: PropTypes.string,
  onRequestEnd: PropTypes.func,
  //+antd Select Props
};

export default RemoteSelect;
