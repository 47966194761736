import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Upload } from 'antd';

import { setActionElements } from '@common';
import { navigator } from '@common/navigation';

import { statusActivePassiveFormatter } from '@utils';
import {
  getProductsURL,
  downloadURL,
  deleteProductSpecURL,
  tradableItemUrl,
} from '@constants/serviceUrls';
import { allowedFileTypes } from '@constants/';

import {
  updateBulkProduct,
  getBulkProductStatus,
} from '@resources/actions/bulkUpdates';
import { inputTypes } from '@components/DynamicForm/constants';
import { checkAuth, getContextChannelPk } from '@common';
import { T } from '@utils/languageProvider';
import { sendRequest } from '@common/network';
import {
  httpMethods,
  activePassiveStringBoolOptions,
} from '@constants/commontypes';
import { bulkFileUpdate } from '@utils/';

import { DraggerFileInput } from '@components/FileInput';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import Block from '@components/Block';
import Button from '@components/Button';
import DatatableWithFilter from '@components/DatatableWithFilter';

import simpleProductImage from '@assets/images/simple-product.svg';
import variantProductImage from '@assets/images/variant-product.svg';
import groupProductImage from '@assets/images/group-product.svg';
import bundleProductImage from '@assets/images/bundle-product.svg';
import miscProductImage from '@assets/images/misc-product.svg';
import './style.scss';

class ProductPool extends Component {
  state = {
    selectedRowKeys: [],
    loading: true,
    productUpdateDisabled: false,
    showUpdateProductInput: false,
    isUploading: false,
  };
  bulkProductsInterval = null;
  filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'series__brand__name',
      name: T('brand'),
      active: true,
      fieldProps: {
        placeholder: T('brand'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'series__name',
      name: T('series'),
      active: true,
      fieldProps: {
        placeholder: T('series'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'name',
      name: T('model'),
      active: true,
      fieldProps: {
        placeholder: T('model'),
      },
    },
    {
      data_type: { value: inputTypes.select.alias },
      key: 'is_active',
      name: T('status'),
      active: true,
      visible_values: activePassiveStringBoolOptions,
      fieldProps: {
        placeholder: T('status'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'min_price',
      name: T('minPrice'),
      active: true,
      fieldProps: {
        placeholder: T('minPrice'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'max_price',
      name: T('maxPrice'),
      active: true,
      fieldProps: {
        placeholder: T('maxPrice'),
      },
    },
    {
      data_type: { value: inputTypes.input.alias },
      key: 'category',
      name: T('category'),
      active: true,
      fieldProps: {
        placeholder: T('category'),
      },
    },
  ].map((filter, index) => ({
    ...filter,
    pk: index + 1,
  }));

  columns = [
    {
      dataIndex: 'series.brand.name',
      exportKey: 'brand_name',
      title: T('brand'),
    },
    {
      dataIndex: 'series.name',
      exportKey: 'series_name',
      title: T('series'),
    },
    {
      dataIndex: 'name',
      title: T('model'),
    },
    {
      dataIndex: 'is_active',
      title: T('status'),
      render: statusActivePassiveFormatter,
    },
    {
      dataIndex: 'price',
      title: T('highestValue'),
    },
    {
      dataIndex: 'series.brand.id',
      exportKey: 'brand',
      title: T('series.brand.id'),
    },
    {
      dataIndex: 'id',
      title: T('model.id'),
    },
    {
      dataIndex: 'series.id',
      exportKey: 'series',
      title: T('series.id'),
    },
    {
      dataIndex: 'category',
      exportKey: 'category',
      title: T('category'),
    },
  ].map((column, index) => ({
    ...column,
    key: index + 1,
  }));

  deleteSelectedRows = () => {
    const { selectedRowKeys } = this.state;
    const promises = selectedRowKeys.map((key) => {
      return sendRequest({
        url: deleteProductSpecURL.format(key),
        method: httpMethods.DELETE,
      });
    });
    Promise.all(promises).then(() => {
      this.datatable.reload();
    });
  };

  rowSelection = {
    onRowSelection: (selectedRowKeys) => {
      this.setState({ selectedRowKeys });
    },
  };

  handleBulkProductUpdate = async (file, fileList) => {
    this.setState({ isUploading: true });
    const { updateBulkProduct, getBulkProductStatus } = this.props;
    let bodyFormData = new FormData();
    fileList.forEach((file) => {
      bodyFormData.append('filename', file);
    });

    await bulkFileUpdate(
      {
        bulkAction: updateBulkProduct,
        cacheKeyAction: getBulkProductStatus,
        bulkActionArgs: [bodyFormData],
        cacheKeyActionName: 'getBulkProductStatus',
      },
      (res) => {
        this.setState({ isUploading: false, showUpdateProductInput: false });
        this.datatable.reload();
      }
    );

    return false;
  };

  componentWillUnmount() {
    setActionElements([]);
  }
  menuData = [
    { type: 0, src: simpleProductImage, label: 'simple.product.add' },
    { type: 1, src: variantProductImage, label: 'variant.product.add' },
    { type: 3, src: groupProductImage, label: 'product.group.add' },
    { type: 2, src: bundleProductImage, label: 'product.bundle.add' },
    { type: 4, src: miscProductImage, label: 'product.miscellaneous.add' },
  ];

  // getDownloadExcel = (format, params) => {
  //   let filteredParams,
  //     queryOptions = {
  //       url: getProductsURL,
  //       _fields: params,
  //       format: format,
  //       limit: 5,
  //       page: 1,
  //       ...filteredParams,
  //     };
  //   const url = `/channel/${getContextChannelPk()}${downloadURL}`;
  //   window.open(addQueryToURL(url, queryOptions), '_blank');
  // };

  // renderCustomProductsFooter = () => {
  //   return (
  //     <Button className="download-btn" ghost onClick={this.handleExcelDownload}>
  //       {T('template.product.pool.download')}
  //     </Button>
  //   );
  // };

  toggleUpdateProduct = () =>
    this.setState({
      showUpdateProductInput: !this.state.showUpdateProductInput,
    });

  // handleExcelDownload = () => this.getDownloadExcel('csv', this.excelFields);

  excelFields = [
    'sku',
    'name',
    'base_code',
    'description',
    'attribute_set__name',
    'attribute_set__description',
    'attributes__is_active',
    'attribute_set__weight',
  ];
  componentDidMount() {
    setActionElements([
      <Block key={0} className="button-area product-pool">
        <Button
          className="product-update-button"
          type="primary"
          onClick={this.toggleUpdateProduct}
        >
          {T('bulk.product.update')}
        </Button>
        <Button
          key="excelAdd"
          type="secondary"
          className="product-dropdown-button"
          icon="icon-arti"
          onClick={() =>
            this.onNavigate(
              '/trade-in/change-products/changeable-products/form'
            )
          }
        >
          {T('new.add').format(T('model'))}
        </Button>
      </Block>,
    ]);
    this.setState({ loading: false });
  }

  onNavigate = (url) => {
    navigator.push(url);
  };

  onRowClick = (row) => {
    this.onNavigate(
      `/trade-in/change-products/changeable-products/form/${row.id}`
    );
  };

  render() {
    const { loading, showUpdateProductInput, isUploading } = this.state;
    return (
      <LayoutContentWrapper className="product-pool-detail" loading={loading}>
        {showUpdateProductInput && (
          <>
            <Block className="excel-update-panel">
              <Block className="col col-start">
                <Block className="excel-update-panel__title">
                  {T('bulk.product.update')}
                </Block>
              </Block>

              <Block className="col w-100">
                <p className="w-100">{T('page.pool.upload.desc')}</p>
                <Block className="file-input-container" loading={isUploading}>
                  <DraggerFileInput
                    fileList={[]}
                    accept={allowedFileTypes}
                    beforeUpload={this.handleBulkProductUpdate}
                    singleAction
                    multiple={true}
                  />
                </Block>
              </Block>
            </Block>
          </>
        )}
        <DatatableWithFilter
          ref={(dt) => (this.datatable = dt)}
          filterProps={{
            staticFilters: this.filterInputs,
          }}
          datatableProps={{
            rowKey: 'id',
            columns: this.columns,
            rowSelection: this.rowSelection,
            loading: isUploading,
            wideColumns: true,
            onRowClick: this.onRowClick,
            subtitle: T('changeable.products'),
            locale: { emptyText: T('result.not.found') },
            paginationText: T('show.variable').format(''),
            url: tradableItemUrl,
          }}
        />
      </LayoutContentWrapper>
    );
  }
}

const mapDispatchToProps = {
  updateBulkProduct,
  getBulkProductStatus,
};

export default connect(null, mapDispatchToProps)(ProductPool);
