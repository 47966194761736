import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { navigator } from '@common/navigation';
import { T } from '@utils/languageProvider';
import { inputTypes } from '@components/DynamicForm/constants';

import DatatableWithFilter from '@components/DatatableWithFilter';
import LayoutContentWrapper from '@components/utility/layoutWrapper/index';
import { setActionElements } from '@common/index';
import Button from '@components/Button';
import { productsUrl } from '@constants/serviceUrls';

const ChangeableProductRulesList = (props) => {
  useEffect(() => {
    setActionElements([
      <Button
        key="excelAdd"
        type="secondary"
        icon="icon-arti"
        onClick={() =>
          onNavigate('/trade-in/change-products/changeable-product-rules/form')
        }
      >
        {T('new.add').format(T('changeable.product.rules'))}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);

  const filterInputs = [
    {
      data_type: { value: inputTypes.input.alias },
      key: 'remote_identifier',
      name: T('code'),
      active: true,
      fieldProps: {
        placeholder: T('code'),
      },
    },
  ].map((filter, index) => ({
    ...filter,
    pk: index + 1,
  }));

  const columns = [
    {
      dataIndex: 'id',
      title: T('id'),
    },
    {
      dataIndex: 'remote_identifier',
      title: T('code'),
      render: (value) => <h1>{value}</h1>,
    },
  ].map((column, index) => ({
    ...column,
    key: index + 1,
  }));

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const onRowClick = (row) => {
    onNavigate(
      `/trade-in/change-products/changeable-product-rules/form/${encodeURIComponent(
        row.remote_identifier
      )}`
    );
  };

  return (
    <LayoutContentWrapper className="stock-lists">
      <DatatableWithFilter
        filterProps={{ filterInputs }}
        datatableProps={{
          rowKey: 'id',
          exportable: false,
          columns: columns,
          onRowClick: onRowClick,
          subtitle: T('changeable.product.rules'),
          locale: { emptyText: T('result.not.found') },
          paginationText: T('show.variable').format(''),
          url: productsUrl,
        }}
      />
    </LayoutContentWrapper>
  );
};

export default ChangeableProductRulesList;
