import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DatatableBox } from '@components/Datatable';
import Box from '@components/utility/box/index';
import Filter from '@components/Filter';
import FilterNoStorage from '@components/Filter/filterNoStorage';
import Block from '@components/Block';

import { sendRequest } from '@common/network';
import { httpMethods } from '@constants/commontypes';
import { getObjectFromString } from '@utils';

class DatatableWithFilter extends Component {
  state = {
    activeColumnFilters: [],
    columns: [],
  };

  componentDidMount() {
    const {
      optionsURL,
      httpMethod,
      columnsObjectKey,
      filterObjectKey,
    } = this.props;
    if (optionsURL) {
      sendRequest({
        method: httpMethod,
        url: optionsURL,
        onSuccess: (result) => {
          this.setState({
            filterInputs: getObjectFromString(filterObjectKey, result),
            columns: getObjectFromString(columnsObjectKey, result),
          });
        },
      });
    }
  }

  filterTable = (filterObject, e) => {
    this.setState({ filterObject });
  };

  onChangeFilter = (filterObject, formFilters) => {
    this.setState({ filterObject, formFilters });
  };

  onChangeActiveFilters = (filters = []) => {
    this.setState({
      activeColumnFilters: filters.filter((filter) => filter.columnProps),
    });
  };

  getColumns = () => {
    let { datatableProps = {} } = this.props;
    const { columns: columnsFromState, activeColumnFilters } = this.state;
    const columns = datatableProps.columns || columnsFromState;

    const leftColumns = columns.filter(
      (col) => col.fixed == 'left' || !col.fixed
    );
    const rightColumns = columns.filter((col) => col.fixed == 'right');

    return this.removeDuplicates([
      ...leftColumns,
      ...activeColumnFilters.map((filter) => ({ ...filter.columnProps })),
      ...rightColumns,
    ]);
  };

  getFilterType = () => {
    const { filterInputs } = this.state;
    const { filterProps } = this.props;
    const ftProps = {
      ...filterProps,
      ...(filterInputs ? { filterInputs } : {}),
    };
    return filterProps.storage === false ? (
      <FilterNoStorage
        onSubmit={this.filterTable}
        onChange={this.onChangeFilter}
        onChangeActiveFilters={this.onChangeActiveFilters}
        inline
        {...ftProps}
      />
    ) : (
      <Filter
        onSubmit={this.filterTable}
        onChange={this.onChangeFilter}
        onChangeActiveFilters={this.onChangeActiveFilters}
        inline
        {...ftProps}
      />
    );
  };

  reload = () => {
    this.datatable.reload();
  };

  addRow = (...args) => {
    this.datatable.addRow(...args);
  };

  removeRow = (...args) => {
    this.datatable.removeRow(...args);
  };

  removeDuplicates = (columns) => {
    const columnNames = [];
    return columns.filter((column) => {
      const hasColumn = columnNames.indexOf(column.dataIndex) !== -1;

      if (!hasColumn) {
        columnNames.push(column.dataIndex);
      }

      return !hasColumn;
    });
  };

  render() {
    const { filterObject, formFilters, filterInputs } = this.state;
    const { datatableProps = {}, filterProps } = this.props;
    const ftProps = {
      ...filterProps,
      ...(filterInputs ? { filterInputs } : {}),
    };
    const { filterFormVisible = true } = ftProps;
    const filterType = this.getFilterType();
    const dtProps = { ...datatableProps, columns: this.getColumns() };

    return (
      <>
        {filterFormVisible ? (
          <Box className="filter-form-wrapper" data-tour="filter-form-wrapper">
            {filterType}
          </Box>
        ) : (
          <Block className="filter-button-container">{filterType}</Block>
        )}
        <DatatableBox
          ref={(dt) => (this.datatable = dt)}
          filter={filterObject}
          formFilters={formFilters}
          {...dtProps}
        />
      </>
    );
  }
}

DatatableWithFilter.defaultProps = {
  filterObjectKey: 'filters',
  columnsObjectKey: 'columns',
  httpMethod: httpMethods.OPTIONS,
};

DatatableWithFilter.propTypes = {
  optionsURL: PropTypes.string,
  filterObjectKey: PropTypes.string,
  columnsObjectKey: PropTypes.string,
  filterProps: PropTypes.shape({
    getFiltersURL: PropTypes.string,
    filterButtons: PropTypes.array,
    staticFilters: PropTypes.array,
    className: PropTypes.string,
    preKey: PropTypes.string,
    posKey: PropTypes.string,
    storage: PropTypes.bool,
    collapsible: PropTypes.bool,
    filterViaFile: PropTypes.bool,
    onChangeActiveFilters: PropTypes.func,
    filterFormVisible: PropTypes.bool,
  }),
  datatableProps: PropTypes.shape({
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    columns: PropTypes.array,
    rowKey: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    type: PropTypes.string,
    rowSelection: PropTypes.object,
    effaceable: PropTypes.bool,
    saveable: PropTypes.bool,
    size: PropTypes.string,
    expandedRowRender: PropTypes.func,
    expandRowByClick: PropTypes.bool,
    horizontalScroll: PropTypes.bool,
    verticalScroll: PropTypes.bool,
    bordered: PropTypes.bool,
    locale: PropTypes.object,
    onChangeDataSource: PropTypes.func,
    actionButtons: PropTypes.array,
    onRowClick: PropTypes.func,
    exportable: PropTypes.bool,
    subtitle: PropTypes.any,
    exportButtonClick: PropTypes.func,
    isAuthorized: PropTypes.bool,
    authActionName: PropTypes.string,
    wideColumns: PropTypes.bool,
  }),
};

export default DatatableWithFilter;
