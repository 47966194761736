import React, { useEffect } from 'react';

import { questionSetUrl } from '@constants/serviceUrls';
import { navigator } from '@common/navigation';
import { setActionElements } from '@common/index';

import { DatatableBox } from '@components/Datatable';
import Button from '@components/Button';

import LayoutContentWrapper from '@components/utility/layoutWrapper/index';

import { T } from '@utils/languageProvider';

const QuestionSet = () => {
  useEffect(() => {
    setActionElements([
      <Button
        type="secondary"
        icon="icon-arti"
        onClick={() => onNavigate('/trade-in/question-set/form/')}
      >
        {T('new.add').format(T('question.set'))}
      </Button>,
    ]);
    return () => setActionElements([]);
  }, []);
  const columns = [
    {
      key: 0,
      dataIndex: 'name',
      title: T('name'),
    },
    {
      key: 1,
      dataIndex: 'slug',
      title: T('slug'),
    },
    {
      key:2,
      dataIndex: 'category',
      title: T('changeable.product.category'), 
      render : (data =>  T(`${data}`))
    }
  ];

  const onNavigate = (url) => {
    navigator.push(url, false);
  };

  const handleRowClick = (row) => {
    onNavigate(`/trade-in/question-set/form/${row.id}`);
  };

  return (
    <LayoutContentWrapper>
      <DatatableBox
        exportable={false}
        onRowClick={handleRowClick}
        url={questionSetUrl}
        columns={columns}
        subtitle={T('question.sets')}
      />
    </LayoutContentWrapper>
  );
};

export default QuestionSet;
